import { Injectable } from '@angular/core';
import { AccountService } from '@squidcloud/console-web/app/account/account.service';
import { ApplicationService } from '@squidcloud/console-web/app/application/application.service';
import { OrganizationService } from '@squidcloud/console-web/app/organization/organization.service';
import { SegmentService } from 'ngx-segment-analytics';

type BaseProperties = {
  appId?: string;
  orgId?: string;
};

const ALLOWED_HOSTS = ['console.getsquid.ai'];

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private baseProperties: BaseProperties = {};

  constructor(
    private readonly applicationService: ApplicationService,
    private readonly organizationService: OrganizationService,
    private readonly accountService: AccountService,
    private readonly segment: SegmentService,
  ) {
    segment.addSourceMiddleware(function ({ payload, next }) {
      const pageUrl = payload.obj?.context?.page?.url;
      if (pageUrl) {
        const url = new URL(pageUrl);
        if (!ALLOWED_HOSTS.includes(url.hostname)) {
          return;
        }
      }
      next(payload);
    });

    void this.page();

    applicationService.observeCurrentApplication().subscribe(application => {
      this.baseProperties.appId = application?.appId;
    });
    organizationService.observeCurrentOrganization().subscribe(organization => {
      this.baseProperties.orgId = organization?.id;
    });
    this.accountService.observeUser().subscribe(user => {
      if (!user) return;
      const { id, email, name, squidDeveloperId } = user;
      void this.segment.identify(squidDeveloperId, { userId: id, email, name, squidDeveloperId });
    });
  }

  page(): Promise<SegmentService> {
    return this.segment.page('Page Viewed', { title: document.title });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  track(event: string, properties?: Record<string, any>, options?: Record<string, any>): Promise<SegmentService> {
    return this.segment.track(event, { ...this.baseProperties, ...properties }, options);
  }
}
