import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { IntegrationType, TopLevelPropertySchema } from '@squidcloud/client';
import { truthy } from 'assertic';
import { BehaviorSubject, filter, Observable, take } from 'rxjs';
import { GlobalUiService } from '../../../global/services/global-ui.service';
import { SnackBarService } from '../../../global/services/snack-bar.service';
import { IntegrationService } from '../../integration.service';
import { Modifications } from '../utils/modifications';
import {
  DataSchemaFieldData,
  DataSchemaFieldDialogComponent,
} from './data-schema-field-dialog/data-schema-field-dialog.component';
import { DataSchemaService } from './data-schema.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AiTestChatFlyOutService } from '../ai-chatbot-profiles/ai-test-chat-fly-out/ai-test-chat-fly-out.service';
import { getRequiredPageParameter, INTEGRATION_ID_PARAMETER } from '@squidcloud/console-web/app/utils/http-utils';
import { DataSchemaFieldType } from '@squidcloud/internal-common/schema/schema.types';
import { DatabaseIntegrationConfig } from '@squidcloud/internal-common/types/integrations/schemas';
import { IntegrationDataSchema } from '@squidcloud/internal-common/types/integrations/database.types';
import { NavigationService } from '@squidcloud/console-web/app/utils/navigation.service';
import { FormElement } from '@squidcloud/console-web/app/utils/form';
import { getSortedKeys } from '@squidcloud/console-web/app/utils/angular-utils';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { isValidId } from '@squidcloud/internal-common/utils/validation';
import { ApplicationService } from '@squidcloud/console-web/app/application/application.service';

export interface CollectionField {
  name: string;
  type: string;
  primaryKey: boolean;
  required: boolean;
  hidden?: boolean;
  modified?: boolean;
}

@Component({
  selector: 'app-data-schema',
  templateUrl: './data-schema.component.html',
  styleUrls: ['./data-schema.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataSchemaComponent implements OnDestroy {
  readonly integrationObs: Observable<DatabaseIntegrationConfig>;
  readonly schemaObs: Observable<IntegrationDataSchema | undefined>;
  readonly modificationsObs: Observable<Modifications>;
  readonly integrationId: string;

  @Input() isNewSchema!: boolean;
  @Output() headerTemplateChange = new EventEmitter<TemplateRef<unknown>>();

  schemaInitialized = false;
  selectedCollectionSubject = new BehaviorSubject<string | undefined>(undefined);
  selectedCollectionFields: Array<CollectionField> = [];

  constructor(
    { snapshot }: ActivatedRoute,
    private readonly navigationService: NavigationService,
    private readonly integrationService: IntegrationService,
    private readonly applicationService: ApplicationService,
    private readonly cdr: ChangeDetectorRef,
    private readonly dataSchemaService: DataSchemaService,
    private readonly globalUiService: GlobalUiService,
    private readonly dialog: MatDialog,
    private readonly snackBar: SnackBarService,
    private readonly destroyRef: DestroyRef,
    private readonly aiTestChatFlyOutService: AiTestChatFlyOutService,
  ) {
    this.integrationId = getRequiredPageParameter(INTEGRATION_ID_PARAMETER, snapshot);
    this.integrationObs = this.integrationService.observeIntegration(
      this.integrationId,
    ) as Observable<DatabaseIntegrationConfig>;
    this.schemaObs = this.dataSchemaService.observeSchema();
    this.modificationsObs = this.dataSchemaService.observeModifications();
    this.integrationObs
      .pipe(take(1), takeUntilDestroyed())
      .subscribe(async (integration: DatabaseIntegrationConfig) => {
        await dataSchemaService.initializeSchema(integration);
        this.schemaInitialized = true;
        cdr.markForCheck();
      });

    // This should only run once when the component loads
    this.schemaObs
      .pipe(
        filter(schema => {
          return Object.keys(schema?.collections || {}).length > 0;
        }),
        take(1),
        takeUntilDestroyed(),
      )
      .subscribe(schema => {
        this.selectFirstCollection(schema);
      });

    // This should run every time the schema changes
    this.schemaObs.pipe(filter(Boolean), takeUntilDestroyed()).subscribe(schema => {
      if (!schema.collections) return;
      this.setSelectedCollectionFields(schema);
    });
  }

  ngOnDestroy(): void {
    this.aiTestChatFlyOutService.closeTestChat();
  }

  get selectedCollection(): string | undefined {
    return this.selectedCollectionSubject.value;
  }

  setSelectedCollectionFields(schema?: IntegrationDataSchema): void {
    if (!this.selectedCollection || !schema) {
      this.selectedCollectionFields = [];
      return;
    }
    const collectionSchema = schema.collections[this.selectedCollection];
    const properties = collectionSchema?.properties || {};
    const requiredSet = new Set([...(collectionSchema?.required || [])]);
    this.selectedCollectionFields = Object.entries<TopLevelPropertySchema>(properties)
      .map<CollectionField>(([fieldName, fieldValue]) => {
        const type = this.getFieldType(truthy(this.selectedCollection), fieldName);
        return {
          name: fieldName,
          type,
          primaryKey: !!fieldValue.primaryKey,
          required: requiredSet.has(fieldName),
          hidden: fieldValue.hidden || false,
          modified: this.dataSchemaService
            .getModifications()
            .isPathModified([this.selectedCollection || '', fieldName]),
        };
      })
      .sort((a, b) => {
        if (a.primaryKey !== b.primaryKey) return a.primaryKey ? -1 : 1;
        return a.name.localeCompare(b.name);
      });
  }

  selectCollection(collectionName: string | undefined): void {
    this.selectedCollectionSubject.next(collectionName);
    this.setSelectedCollectionFields(collectionName ? this.dataSchemaService.getSchemaOrFail() : undefined);
    this.cdr.markForCheck();
    if (!collectionName) return;
  }

  showAddCollectionDialog(integrationType: IntegrationType): void {
    this.globalUiService
      .showDialogWithForm<{ name: string }>({
        title: 'Add collection?',
        textLines: ['Descriptive text to assist with naming, not to worry the name can be updated later.'],
        submitButtonText: 'Create',
        formElements: [
          {
            type: 'input',
            required: true,
            nameInForm: 'name',
            label: 'Name collection',
            extraValidators: [this.collectionNameValidator(integrationType)],
            showErrorInTooltip: true,
          },
        ],
        onSubmit: (data): string | void => {
          if (!data) return;
          const collectionName = data.name;
          const { collections } = this.dataSchemaService.getSchemaOrFail();
          const existingCollection = Object.keys(collections || {}).find(
            collection => collection.toLowerCase().trim() === collectionName.toLowerCase().trim(),
          );
          if (existingCollection) {
            return 'Collection already exists, try a different name';
          }
          this.dataSchemaService.addCollectionToSchema(collectionName);
          this.selectCollection(collectionName);
        },
      })
      .then();
  }

  showEditCollectionDialog(integrationType: IntegrationType): void {
    const schema = this.dataSchemaService.getSchemaOrFail();
    const collectionName = truthy(this.selectedCollection);
    const collection = schema.collections[collectionName];
    const description = collection.description;
    this.integrationObs.pipe(take(1), takeUntilDestroyed(this.destroyRef)).subscribe(integration => {
      const formElements: Array<FormElement> = [
        {
          type: 'input',
          required: true,
          nameInForm: 'name',
          label: 'Name collection',
          defaultValue: collectionName,
          extraValidators: [this.collectionNameValidator(integrationType)],
          showErrorInTooltip: true,
        },
        {
          type: 'textarea',
          required: false,
          nameInForm: 'description',
          label: 'Description (used for AI queries)',
          defaultValue: description,
          attributes: {
            autosize: false,
            minHeight: 100,
          },
        },
      ];

      if ([IntegrationType.built_in_db, IntegrationType.mongo].includes(integration.type)) {
        formElements.push({
          type: 'boolean',
          label: 'Allow extra fields',
          hint: 'Allows users to insert fields not specified in schema.',
          nameInForm: 'allowExtraFields',
          required: true,
          defaultValue: collection.additionalProperties ?? false,
        });
      }
      this.globalUiService
        .showDialogWithForm<{ name: string; allowExtraFields: boolean; description: string }>({
          title: 'Collection settings',
          textLines: [],
          submitButtonText: 'Update',
          formElements,
          onSubmit: (data): string | void => {
            if (!data) return;
            this.dataSchemaService.updateCollection(collectionName, data.name, data.allowExtraFields, data.description);
            this.selectCollection(data['name']);
          },
          onDelete: this.showDeleteSchemaCollectionDialog.bind(this),
          minRole: 'ADMIN',
        })
        .then();
    });
  }

  showEditFieldDialog(collectionName: string, fieldName: string, integrationType: IntegrationType): void {
    const schema = this.dataSchemaService.getSchemaOrFail();
    const collection = truthy(
      schema.collections[collectionName],
      `No collection with name '${collectionName}' found in schema.`,
    );
    const field = truthy(collection.properties?.[fieldName]);

    const type = this.getFieldType(collectionName, fieldName);

    this.showDataSchemaFieldDialog({
      collectionName,
      type: (type || 'any') as DataSchemaFieldType,
      name: fieldName,
      required: (collection.required || []).includes(fieldName),
      primaryKey: !!field.primaryKey,
      defaultValue: field.default,
      min: field.type === 'string' ? field.minLength : field.minimum,
      max: field.type === 'string' ? field.maxLength : field.maximum,
      description: field.description,
      integrationType: integrationType,
      hidden: field.hidden,
    });
  }

  showAddFieldDialog(collectionName: string, integrationType: IntegrationType): void {
    this.showDataSchemaFieldDialog({ collectionName, integrationType });
  }

  private showDataSchemaFieldDialog(data: DataSchemaFieldData): void {
    const config: MatDialogConfig = {
      maxWidth: '496px',
      width: '100%',
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'modal',
      data,
    };
    this.dialog.open(DataSchemaFieldDialogComponent, config);
  }

  showDeleteSchemaCollectionDialog(): void {
    this.globalUiService.showConfirmationDialog(
      'Arrrrr you sure?',
      `This will remove the '${this.selectedCollection}' collection from the schema.`,
      'Delete',
      () => {
        this.dataSchemaService.deleteCollectionFromSchema(truthy(this.selectedCollection));
        void this.dataSchemaService.deleteBuiltInCollection(truthy(this.selectedCollection));
        this.selectFirstCollection(this.dataSchemaService.getSchemaOrFail());
      },
    );
  }

  showDeleteFieldDialog(fieldName: string): void {
    this.globalUiService.showConfirmationDialog(
      'Arrrrr you sure?',
      `This will delete ${fieldName} from the ${this.selectedCollection} collection.`,
      'Delete',
      () => {
        this.dataSchemaService.deleteCollectionField(truthy(this.selectedCollection), fieldName);
      },
    );
  }

  duplicateField(fieldName: string): void {
    this.dataSchemaService.duplicateField(truthy(this.selectedCollection), fieldName);
  }

  toggleHiddenField(collectionName: string, fieldName: string): void {
    this.dataSchemaService.toggleHiddenField(collectionName, fieldName);
  }

  private selectFirstCollection(schema?: IntegrationDataSchema): void {
    if (!schema) return;
    const collections = Object.keys(schema.collections || []).sort();
    this.selectCollection(collections[0]);
  }

  async saveSchema(integrationType: IntegrationType): Promise<void> {
    try {
      const schema = this.dataSchemaService.getSchemaOrFail();
      // Validation stage
      if (integrationType !== IntegrationType.built_in_db) {
        for (const [collectionName, collectionSchema] of Object.entries(schema.collections)) {
          const properties = collectionSchema.properties || {};
          if (!Object.values(properties).find(propertyValue => !!propertyValue.primaryKey)) {
            this.snackBar.warning(`The '${collectionName}' collection must have a primary key`);
            return;
          }

          if (!Object.values(properties).find(propertyValue => !!propertyValue.primaryKey && !propertyValue.hidden)) {
            this.snackBar.warning(`The '${collectionName}' collection must have a non-hidden primary key`);
            return;
          }
        }
      }

      const navGuard = this.navigationService.newNavigationGuard();
      await this.dataSchemaService.saveSchema();
      this.snackBar.success(this.isNewSchema ? 'Integration added' : 'Schema saved');
      if (this.isNewSchema) {
        const appId = this.applicationService.getCurrentApplication();
        if (appId) {
          await navGuard.navigateByUrl(`/application/${appId.appId}/integration/${this.integrationId}/db-browser`);
        } else {
          await navGuard.navigateByUrl('/integrations');
        }
      }
    } catch (e) {
      console.error('Unable to save schema', e);
      this.snackBar.warning('Unable to save schema, please try again later');
      return;
    }
  }

  showRediscoverSchemaDialog(): void {
    this.globalUiService.showConfirmationDialog(
      'Rediscover Schema',
      [
        'Are you sure you want to rediscover the schema? This action fetches the schema from the underlying database and may overwrite data in the saved schema.',
        'Note that these changes are not saved automatically.',
      ],
      'Confirm',
      async () => {
        await this.discoverSchema();
      },
    );
  }

  async discoverSchema(): Promise<void> {
    try {
      await this.dataSchemaService.discoverSchema();
      this.snackBar.success('Schema discovered');
    } catch (e: unknown) {
      if (e instanceof Error && e.message.includes('DATABASE CONNECTION FAILED')) {
        this.snackBar.warning(
          'Login to db failed. Please double-check your connection settings (including secrets) and try again.',
        );
      } else {
        this.snackBar.warning('Unable to discover schema, please try again later');
      }
      console.error('Unable to discover schema', e);
    }
  }

  private getFieldType(collectionName: string, fieldName: string): DataSchemaFieldType {
    const schema = this.dataSchemaService.getSchemaOrFail();
    const collection = truthy(
      schema.collections[collectionName],
      `No collection with name '${collectionName}' found in schema.`,
    );
    const field = truthy(collection.properties?.[fieldName]);

    let type = field.type;
    if (type === 'object') {
      if (field.isDate) type = 'date';
      if (field.isJSON) type = 'json';
    }

    if (field?.dataType === 'objectId') {
      type = 'objectId';
    }

    return type as DataSchemaFieldType;
  }

  toggleAiQueryTest(): void {
    this.aiTestChatFlyOutService.setIntegrationIdAndProfileId(this.integrationId, undefined, true);
    this.aiTestChatFlyOutService.toggleTestChat();
  }

  disableSaveSchemaButton(isAdmin: boolean, modified: boolean, integrationType: IntegrationType): boolean {
    if (!isAdmin || !this.schemaInitialized) {
      return true;
    }
    if (integrationType === IntegrationType.built_in_db) {
      return !modified;
    }

    if (modified) {
      const schema = this.dataSchemaService.getSchemaOrFail();
      return Object.entries(schema.collections).length === 0;
    }
    return !this.isNewSchema;
  }

  showTypeScriptInterfaceDialog(collectionName: string): void {
    const typescriptTypes = this.dataSchemaService.generateTypeScriptTypes(collectionName);
    if (!typescriptTypes) {
      this.snackBar.warning('Unable to generate TypeScript types');
      return;
    }

    this.globalUiService.showDocDialog({
      params: { typescriptTypes, collectionName },
      mdFilePath: 'assets/docs/schema/db-typescript-interface.md',
    });
  }

  private collectionNameValidator(integrationType: IntegrationType): ValidatorFn {
    return (control: AbstractControl) => {
      if (integrationType !== IntegrationType.built_in_db) return null;
      return isValidId(control.value)
        ? null
        : { invalidCollectionName: 'Only letters, numbers, dashes and underscores allowed.' };
    };
  }

  protected readonly getSortedKeys = getSortedKeys;
}
