import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
  TemplateRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AiChatModelName } from '@squidcloud/client';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { IntegrationService } from '../../integration.service';

import { FormControl } from '@angular/forms';
import { GlobalUiService } from '../../../global/services/global-ui.service';
import { SnackBarService } from '../../../global/services/snack-bar.service';
import { AiChatbotProfilesService } from './ai-chatbot-profiles.service';
import { AiTestChatFlyOutService } from './ai-test-chat-fly-out/ai-test-chat-fly-out.service';
import { isNonNullable, truthy } from 'assertic';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EmbedWidgetDialogComponent } from './embed-widget-dialog/embed-widget-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { getRequiredPageParameter, INTEGRATION_ID_PARAMETER } from '@squidcloud/console-web/app/utils/http-utils';
import { getMessageFromError } from '@squidcloud/internal-common/utils/error-utils';
import {
  AiChatbotIntegrationConfig,
  AiChatbotProfiles,
} from '@squidcloud/internal-common/types/integrations/ai_chatbot.types';
import { CpIntegration } from '@squidcloud/console-common/types/application.types';
import { FormElement } from '@squidcloud/console-web/app/utils/form';
import {
  StorylaneDemo,
  StorylaneDialogService,
} from '@squidcloud/console-web/app/global/components/storylane-dialog/storylane-dialog.service';
import { getEntries, getSortedKeys } from '@squidcloud/console-web/app/utils/angular-utils';

const TEST_CHATS: Record<string, string> = {
  onboarding: `Hello! I can help you understand car insurance. You can ask me questions like "What does my policy cover?" or "What impacts my insurance costs?" and more.`,
};

@Component({
  selector: 'ai-chatbot-profiles',
  templateUrl: '/ai-chatbot-profiles.component.html',
  styleUrls: ['./ai-chatbot-profiles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiChatbotProfilesComponent implements OnDestroy {
  readonly integrationObs: Observable<AiChatbotIntegrationConfig>;
  readonly chatbotProfiles: Observable<AiChatbotProfiles | undefined>;
  readonly integrationId: string;
  readonly tipParam: string | undefined;

  private lastDemo: StorylaneDemo | undefined = undefined;
  private storylaneProfile: string | undefined;

  strictContextControl = new FormControl(false);

  ModelNameMap: Record<string, string> = {
    'gpt-4o': 'GPT-4o Stable',
    'gpt-4o-2024-08-06': 'GPT-4o 2024-08-06',
    'gpt-4o-mini': 'GPT-4o Mini',
    'o1-preview': 'GPT-o1 Preview',
    'o1-mini': 'GPT-o1 Mini',
    'gemini-1.5-pro': 'Gemini Pro 1.5',
    'gemini-1.5-flash': 'Gemini Flash 1.5',
    'gemini-pro': 'Gemini Pro 1.0',
    'claude-3-opus-20240229': 'Claude 3 Opus',
    'claude-3-sonnet-20240229': 'Claude 3 Sonnet',
    'claude-3-5-sonnet-20240620': 'Claude 3.5 Sonnet',
    'claude-3-haiku-20240307': 'Claude 3 Haiku',
    'gpt-4-turbo': 'GPT-4 Turbo',
    'gpt-4-turbo-preview': 'GPT-4 Turbo (Preview)',
    'gpt-4': 'GPT-4',
    'gpt-3.5-turbo': 'GPT 3.5',
  };

  @Output() headerTemplateChange = new EventEmitter<TemplateRef<unknown>>();

  selectedProfileSubject = new BehaviorSubject<string | undefined>(undefined);

  constructor(
    { snapshot }: ActivatedRoute,
    private readonly dialog: MatDialog,
    private readonly integrationService: IntegrationService,
    private readonly aiChatbotProfilesService: AiChatbotProfilesService,
    private readonly cdr: ChangeDetectorRef,
    private readonly globalUiService: GlobalUiService,
    private readonly snackBar: SnackBarService,
    private readonly aiTestChatFlyOutService: AiTestChatFlyOutService,
    private readonly storylane: StorylaneDialogService,
  ) {
    this.tipParam = snapshot.queryParams['tip'];

    this.integrationId = getRequiredPageParameter(INTEGRATION_ID_PARAMETER, snapshot);
    this.integrationObs = this.integrationService.observeIntegration(
      this.integrationId,
    ) as Observable<AiChatbotIntegrationConfig>;
    this.chatbotProfiles = this.aiChatbotProfilesService.observeProfiles();
    this.integrationObs
      .pipe(take(1), takeUntilDestroyed())
      .subscribe(async (integration: AiChatbotIntegrationConfig) => {
        await aiChatbotProfilesService.initializeAiSchema(integration);
      });

    this.chatbotProfiles.pipe(takeUntilDestroyed()).subscribe(schema => {
      this.setSelectedProfile(schema);
    });

    this.strictContextControl.valueChanges.subscribe((checked: boolean | null) => {
      void this.handleStrictContextCheck(checked);
    });
    this.aiTestChatFlyOutService
      .observeChatHistory()
      .pipe(takeUntilDestroyed())
      .subscribe(history => {
        if (!history.length) return;

        const lastMessage = history[history.length - 1];
        if (!this.isOnboarding || lastMessage.type === 'user') {
          this.storylane.clear();
          return;
        }

        // Play the demo if the profile has changed and we haven't yet shown the final demo.
        if (this.selectedProfile !== this.storylaneProfile && this.lastDemo !== StorylaneDemo.AI_CONGRATS) {
          const demo = this.lastDemo ? StorylaneDemo.AI_CONGRATS : StorylaneDemo.AI_LEARN;
          this.storylane.play(demo, 5000).then(success => {
            if (!success) return;
            this.storylaneProfile = this.selectedProfile;
            this.lastDemo = demo;
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.aiTestChatFlyOutService.closeTestChat();
  }

  hasProfiles(): boolean {
    const schema = this.aiChatbotProfilesService.getSchemaOrFail();
    return !!Object.keys(schema.profiles).length;
  }

  showAddContextDialog(): void {
    this.globalUiService
      .showDialogWithForm<AiFormDetailsType>(
        {
          title: 'Add Context',
          textLines: ['Provide specific additional knowledge the agent should use when answering users.'],
          submitButtonText: 'Add Context',
          formElements: [
            {
              type: 'input',
              required: true,
              nameInForm: 'title',
              label: 'Context title',
            },
            {
              type: 'select',
              required: true,
              nameInForm: 'type',
              label: 'Context type',
              defaultValue: 'text',
              options: [
                { name: 'Raw text', value: 'text' },
                { name: 'URL', value: 'url' },
                { name: 'File upload', value: 'file' },
              ],
            },
            {
              onChange: (formElement: FormElement, data: AiFormDetailsType): FormElement => {
                formElement.hidden = data.type !== 'text';
                return formElement;
              },
              type: 'textarea',
              required: true,
              nameInForm: 'text',
              label: 'Enter context',
              placeholder: 'Provide contextual knowledge here',
              attributes: {
                autosize: true,
                minRows: 12,
                maxRows: 16,
              },
            },
            {
              onChange: (formElement: FormElement, data: AiFormDetailsType): FormElement => {
                formElement.hidden = data.type !== 'url';
                return formElement;
              },
              type: 'input',
              required: true,
              nameInForm: 'url',
              label: 'Enter URL',
              hidden: true,
            },
            {
              onChange: (formElement: FormElement, data: AiFormDetailsType): FormElement => {
                formElement.hidden = data.type !== 'file';
                return formElement;
              },
              type: 'file',
              required: true,
              nameInForm: 'file',
              label: 'File upload',
              hidden: true,
              fileTypes: 'pdf, docx, html, and any text file',
            },
          ],
          onSubmit: async (data): Promise<string | void> => {
            if (!data) return;
            const title = data.title;
            const type = data.type;
            let value: undefined | string;
            switch (type) {
              case 'url':
                value = data.url;
                break;
              case 'file':
                value = data.file?.name;
                break;
              default:
                value = data.text;
                break;
            }
            try {
              await this.aiChatbotProfilesService.addContext(
                this.selectedProfileOrFail,
                title,
                {
                  type,
                  data: truthy(value, 'NO_CONTEXT_VALUE'),
                },
                (data as { file?: File }).file,
              );
              this.snackBar.success('Context added');
            } catch (error: unknown) {
              const message = error instanceof Error ? error.message : 'Unable to add context';
              this.snackBar.warning(message);
            }
          },
        },
        true,
      )
      .then();
  }

  showAddInstructionDialog(): void {
    this.globalUiService
      .showDialogWithForm<{ instruction: string }>(
        {
          title: 'Add instruction',
          textLines: ['Provide instructions for the LLM to follow when interacting with users.'],
          submitButtonText: 'Add instructions',
          formElements: [
            {
              type: 'textarea',
              required: true,
              nameInForm: 'instruction',
              placeholder: 'Enter instructions here',
              label: 'Instructions',
              attributes: {
                autosize: true,
                minRows: 10,
                maxRows: 15,
              },
            },
          ],
          onSubmit: async (data): Promise<string | void> => {
            if (!data) return;
            const instruction = data.instruction;
            try {
              await this.aiChatbotProfilesService.addInstruction(this.selectedProfileOrFail, instruction);
              this.snackBar.success('Instruction added');
            } catch (error: unknown) {
              const message = error instanceof Error ? error.message : 'Unable to add instructions';
              this.snackBar.warning(message);
            }
          },
        },
        true,
      )
      .then();
  }

  showDeleteContextDialog(id: string): void {
    this.globalUiService.showConfirmationDialog(
      'Arrrrr you sure?',
      `Deleting this context cannot be undone or recovered, and it will be removed from this profile.`,
      'Delete',
      async () => {
        try {
          await this.aiChatbotProfilesService.deleteContext(this.selectedProfileOrFail, id);
          this.snackBar.success('Context deleted');
        } catch {
          this.snackBar.warning('Unable to delete context');
        }
      },
    );
  }

  showDeleteInstructionDialog(id: string): void {
    this.globalUiService.showConfirmationDialog(
      'Arrrrr you sure?',
      `Deleting this instruction cannot be undone or recovered, and it will be removed from this profile.`,
      'Delete',
      async () => {
        try {
          await this.aiChatbotProfilesService.deleteInstruction(this.selectedProfileOrFail, id);
          this.snackBar.success('Instruction deleted');
        } catch {
          this.snackBar.warning('Unable to delete instruction');
        }
      },
    );
  }

  showEditInstructionDialog(profileId: string, instructionId: string, instructions: string): void {
    this.globalUiService
      .showDialogWithForm<{ instructions: string }>({
        title: 'Edit instructions',
        submitButtonText: 'Save',
        textLines: ['Edit the instructions for this chatbot'],
        autoFocus: true,
        formElements: [
          {
            type: 'textarea',
            required: true,
            nameInForm: 'instructions',
            defaultValue: instructions,
            label: 'Instructions',
            attributes: {
              autosize: true,
              minRows: 10,
              maxRows: 15,
            },
          },
        ],
        onSubmit: async data => {
          try {
            await this.aiChatbotProfilesService.updateInstruction(profileId, instructionId, data.instructions);
            this.snackBar.success('Instructions updated');
          } catch (error) {
            console.error('Unable to update instructions', error);
            this.snackBar.warning(`Unable to update instructions: ${getMessageFromError(error)}`);
          }
        },
      })
      .then();
  }

  showDeleteProfileDialog(id: string): void {
    void this.globalUiService.showDeleteDialog(
      `Deleting the ${id} profile cannot be undone or recovered, and it will be removed from this integration.`,
      async () => {
        try {
          await this.aiChatbotProfilesService.deleteProfile(this.selectedProfileOrFail);
          this.snackBar.success('Profile deleted');
          this.selectFirstProfile();
        } catch {
          this.snackBar.warning('Unable to delete profile');
        }
      },
      `Arrrrr you sure?`,
    );
  }

  showProfileDialog(id?: string): void {
    const isEdit = !!id;

    const modelOptions = Object.entries(this.ModelNameMap).map(([value, name]) => ({ name, value }));

    const schema = this.aiChatbotProfilesService.getSchemaOrFail();
    const profile = isEdit ? schema.profiles[id] : undefined;

    this.globalUiService
      .showDialogWithForm<{ id: string; modelName: AiChatModelName; public: boolean }>({
        title: `${isEdit ? 'Edit' : 'Add'} profile?`,
        minRole: 'ADMIN',
        textLines: ['Profiles allow you to inject context and instructions into your AI model prompts'],
        submitButtonText: isEdit ? 'Update' : 'Add',
        formElements: [
          {
            type: 'input',
            required: true,
            nameInForm: 'id',
            label: 'Profile ID',
            defaultValue: id,
            readonly: isEdit,
          },
          {
            type: 'select',
            required: true,
            nameInForm: 'modelName',
            label: 'Model name',
            options: modelOptions,
            defaultValue: profile?.modelName,
          },
          {
            type: 'boolean',
            required: true,
            nameInForm: 'public',
            label: 'Set profile to public',
            description:
              'Public profiles are accessible to all users. Toggle this setting and view <a href="https://docs.squid.cloud/docs/development-tools/backend/security-rules/secure-ai-chatbot/" target="_blank">documentation</a> to restrict access.',
            defaultValue: isNonNullable(profile?.isPublic) ? profile?.isPublic : false,
          },
        ],
        onSubmit: async (data): Promise<string | void> => {
          if (!data) return;
          const profileId = data.id;
          const modelName = data.modelName;
          const isPublic = data.public;

          if (isEdit) {
            try {
              await this.aiChatbotProfilesService.updateProfile(profileId, {
                modelName,
                isPublic,
              });
              this.snackBar.success('Profile updated');
            } catch (error: unknown) {
              const message = error instanceof Error ? error.message : 'Unable to update profile';
              this.snackBar.warning(message);
            }
          } else {
            try {
              await this.aiChatbotProfilesService.addProfile(profileId, {
                modelName,
                isPublic,
                strictContext: false,
              });
              this.selectProfile(profileId);
              this.snackBar.success('Profile created');
            } catch (error: unknown) {
              const message = error instanceof Error ? error.message : 'Unable to create profile';
              this.snackBar.warning(message);
            }
          }
        },
      })
      .then();
  }

  setSelectedProfile(schema: AiChatbotProfiles | undefined): void {
    if (!this.selectedProfileSubject.value) {
      if (schema?.profiles) {
        this.selectFirstProfile();
      }
    } else {
      if (!schema?.profiles[this.selectedProfileSubject.value]) {
        if (schema?.profiles) {
          this.selectFirstProfile();
        } else {
          this.selectProfile(undefined);
        }
      }
    }
  }

  selectProfile(profileId: string | undefined, introText?: string): void {
    const schema = this.aiChatbotProfilesService.getSchemaOrFail();

    this.selectedProfileSubject.next(profileId);

    if (profileId) {
      this.strictContextControl.setValue(schema.profiles[profileId].strictContext, { emitEvent: false });
      this.aiTestChatFlyOutService.setIntegrationIdAndProfileId(this.integrationId, profileId, false, introText);
    }

    this.cdr.markForCheck();
  }

  get selectedProfile(): string | undefined {
    return this.selectedProfileSubject.value;
  }

  get selectedProfileOrFail(): string {
    return truthy(this.selectedProfile);
  }

  private selectFirstProfile(): void {
    const schema = this.aiChatbotProfilesService.getSchemaOrFail();
    const profiles = Object.keys(schema.profiles || []).sort();

    const introText = this.tipParam ? TEST_CHATS[this.tipParam] : undefined;
    this.selectProfile(profiles[0], introText);

    if (introText) {
      this.toggleTestChat();
      if (this.isOnboarding) {
        void this.storylane.play(StorylaneDemo.AI_SPLASH, 1000);
      }
    }
  }

  hasInstructions(profileId: string): boolean {
    const schema = this.aiChatbotProfilesService.getSchemaOrFail();
    return !!Object.keys(schema.profiles[profileId].instructions || {}).length;
  }

  hasContext(profileId: string): boolean {
    const schema = this.aiChatbotProfilesService.getSchemaOrFail();
    return !!Object.keys(schema.profiles[profileId].contexts || {}).length;
  }

  async handleStrictContextCheck(checked: boolean | null): Promise<void> {
    const schema = this.aiChatbotProfilesService.getSchemaOrFail();
    const profile = schema.profiles[this.selectedProfileOrFail];
    try {
      await this.aiChatbotProfilesService.updateProfile(this.selectedProfileOrFail, {
        ...profile,
        strictContext: !!checked,
      });
      this.snackBar.success('Profile context settings updated');
    } catch {
      this.strictContextControl.setValue(!checked, { emitEvent: false });
      this.snackBar.warning('Unable to update profile');
    }
  }

  toggleTestChat(): void {
    this.aiTestChatFlyOutService.toggleTestChat();
  }

  showEmbedWidgetDialog(integration: CpIntegration): void {
    EmbedWidgetDialogComponent.show(this.dialog, { integration, profileId: this.selectedProfile });
  }

  get isOnboarding(): boolean {
    return this.tipParam === 'onboarding';
  }

  protected readonly getSortedKeys = getSortedKeys;
  protected readonly getEntries = getEntries;
}

type AiFormDetailsType = { title: string; name: string } & (
  | { type: 'file'; file?: File }
  | { type: 'text'; text?: string }
  | { type: 'url'; url?: string }
);
