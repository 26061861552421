import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NgZone } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule as Auth0Module, AuthService as Auth0Service } from '@auth0/auth0-angular';
import { datadogLogs } from '@datadog/browser-logs';
import { Squid } from '@squidcloud/client';
import { CpApplication } from '@squidcloud/console-common/types/application.types';
import { OpenApiTutorialComponent } from '@squidcloud/console-web/app/backend/openapi/openapi-tutorial/openapi-tutorial.component';
import { OpenApiComponent } from '@squidcloud/console-web/app/backend/openapi/openapi.component';
import { PopoverModule } from '@squidcloud/console-web/app/global/popover/popover.module';
import { OrganizationBillsListComponent } from '@squidcloud/console-web/app/organization/organization-bills-list/organization-bills-list.component';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha-2';
import { MarkdownModule } from 'ngx-markdown';
import { DEFAULT_CONFIG as SEGMENTS_DEFAULT_CONFIG, SegmentModule } from 'ngx-segment-analytics';
import { filter, firstValueFrom, Observable, take } from 'rxjs';
import { environment } from '../environments/environment';
import { ProfileSettingsComponent } from './account/profile-settings/profile-settings.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApplicationOverviewComponent } from './application/application-overview/application-overview.component';
import { ApplicationSettingsComponent } from './application/application-settings/application-settings.component';
import { ApplicationService } from './application/application.service';
import { CreateApplicationDialogComponent } from './application/create-application-dialog/create-application-dialog.component';
import { CreateApplicationFormComponent } from './application/create-application-form/create-application-form.component';
import { DocumentationWithComponentDialogComponent } from '@squidcloud/console-web/app/application/documentation-with-component-dialog/documentation-with-component-dialog.component';
import { LogEntryDialogComponent } from './application/logs/log-entry-dialog/log-entry-dialog.component';
import { LogLevelComponent } from './application/logs/log-level/log-level.component';
import { LogsComponent } from './application/logs/logs.component';
import { NoApplicationZeroStateComponent } from './application/no-application-zero-state/no-application-zero-state.component';
import { CreateSecretDialogComponent } from './application/secrets/create-secret-dialog/create-secret-dialog.component';
import { SecretsComponent } from './application/secrets/secrets.component';
import { SelectSecretComponent } from './application/secrets/select-secret/select-secret.component';
import { UpdateSecretDialogComponent } from './application/secrets/update-secret-dialog/update-secret-dialog.component';
import { BackendComponent } from './backend/backend.component';
import { CreateEnvFileDocComponent } from './backend/create-env-file-doc/create-env-file-doc.component';
import { DeployBackendDocComponent } from './backend/deploy-backend-doc/deploy-backend-doc.component';
import { ExecutablesTutorialComponent } from './backend/executables/executables-tutorial/executables-tutorial.component';
import { ExecutablesComponent } from './backend/executables/executables.component';
import { InitializeBackendTutorialComponent } from './backend/initialize-backend-tutorial/initialize-backend-tutorial.component';
import { SchedulersTutorialComponent } from './backend/schedulers/schedulers-tutorial/schedulers-tutorial.component';
import { SchedulersComponent } from './backend/schedulers/schedulers.component';
import { SecurityRulesTutorialComponent } from './backend/security-rules/security-rules-tutorial/security-rules-tutorial.component';
import { SecurityRulesComponent } from './backend/security-rules/security-rules.component';
import { ShowEnvVarsDocComponent } from './backend/show-env-vars-doc/show-env-vars-doc.component';
import { TriggersTutorialComponent } from './backend/triggers/triggers-tutorial/triggers-tutorial.component';
import { TriggersComponent } from './backend/triggers/triggers.component';
import { WebhooksTutorialComponent } from './backend/webhooks/webhooks-tutorial/webhooks-tutorial.component';
import { WebhooksComponent } from './backend/webhooks/webhooks.component';
import { FeedbackDialogComponent } from '@squidcloud/console-web/app/global/components/feedback-dialog/feedback-dialog.component';
import { BundleDataTableComponent } from './global/components/bundle-data-table/bundle-data-table.component';
import { ActionComponent } from './global/components/cards/action/action.component';
import { InfoComponent } from './global/components/cards/info/info.component';
import { CopyCodeComponent } from './global/components/copy-code/copy-code.component';
import { FileUploadComponent } from './global/components/magic-form/file-upload/file-upload.component';
import { DialogWithFormComponent } from './global/components/dialog-with-form/dialog-with-form.component';
import { DocComponent } from './global/components/doc/doc.component';
import { EmailVerificationComponent } from './global/components/email-verification/email-verification.component';
import { HeaderComponent } from './global/components/header/header.component';
import { ComingSoonComponent } from './global/components/illustrations/coming-soon/coming-soon.component';
import { IllustrationComponent } from './global/components/illustrations/illustration/illustration.component';
import { NothingToSeeComponent } from './global/components/illustrations/nothing-to-see/nothing-to-see.component';
import { InputCardComponent } from './global/components/input-card/input-card.component';
import { LogoutComponent } from './global/components/logout/logout.component';
import { MiniBundleDataTableComponent } from './global/components/mini-bundle-data-table/mini-bundle-data-table.component';
import { OnDestroyComponent } from './global/components/on-destroy/on-destroy.component';
import { PageZeroStateComponent } from './global/components/page-zero-state/page-zero-state.component';
import { PortalComponent } from './global/components/portal/portal.component';
import { ProtectedLayoutComponent } from './global/components/protected-layout/protected-layout.component';
import { QuotedActionDialogComponent } from './global/components/quoted-action-dialog/quoted-action-dialog.component';
import { RudderChatComponent } from './global/components/rudder-chat/rudder-chat.component';
import { WidgetContactComponent } from './global/components/rudder-chat/widget-contact/widget-contact.component';
import { SidebarLayoutComponent } from './global/components/sidebar-layout/sidebar-layout.component';
import { StatusPageComponent } from './global/components/status-page/status-page.component';
import { AutofocusDirective } from './global/directives/autofocus.directive';
import { LabelWithPrefixDirective } from './global/directives/label-with-prefix.directive';
import { NgVarDirective } from './global/directives/ng-var.directive';
import { MinRolePipe } from './global/pipes/min-role.pipe';
import { ObjectEntriesPipe } from './global/pipes/object-entries.pipe';
import { ObjectKeysPipe } from './global/pipes/object-keys.pipe';
import { ObjectValuesPipe } from './global/pipes/object-values.pipe';
import { ParseAppIdPipe } from './global/pipes/parse-app-id.pipe';
import { SortPipe } from './global/pipes/sort.pipe';
import { ThemeService } from './global/services/theme.service';
import { AvailableIntegrationsComponent } from './integrations/available-integrations/available-integrations.component';
import { InjectionTableComponent } from './integrations/components/injection-table/injection-table.component';
import { IntegrationHeaderComponent } from './integrations/components/integration-header/integration-header.component';
import { IntegrationIdComponent } from './integrations/components/integration-id/integration-id.component';
import { MiniBackendFunctionsComponent } from './integrations/components/mini-backend-functions/mini-backend-functions.component';
import { CurrentIntegrationsComponent } from './integrations/current-integrations/current-integrations.component';
import { InjectionTableFormComponent } from './integrations/integration-form/components/injection-table-form/injection-table-form.component';
import { IntegrationFormComponent } from './integrations/integration-form/integration-form.component';
import { IntegrationComponent } from './integrations/integration/integration.component';
import { IntegrationsComponent } from './integrations/integrations/integrations.component';
import { MetricsComponent } from './integrations/metrics/metrics.component';
import { AiChatbotProfilesComponent } from './integrations/schema/ai-chatbot-profiles/ai-chatbot-profiles.component';
import { AiTestChatFlyOutComponent } from './integrations/schema/ai-chatbot-profiles/ai-test-chat-fly-out/ai-test-chat-fly-out.component';
import { EmbedWidgetDialogComponent } from './integrations/schema/ai-chatbot-profiles/embed-widget-dialog/embed-widget-dialog.component';
import { ApiSchemaComponent } from './integrations/schema/api-schema/api-schema.component';
import { ReactGraphiqlLazyLoadedComponent } from './integrations/schema/components/react-graphiql/react-graphiql-lazy-loaded.component';
import { SchemaCardItemComponent } from './integrations/schema/components/schema-card-item/schema-card-item.component';
import { SchemaCardComponent } from './integrations/schema/components/schema-card/schema-card.component';
import { SchemaFieldMenuComponent } from './integrations/schema/components/schema-field-menu/schema-field-menu.component';
import { SchemaSelectRowComponent } from './integrations/schema/components/schema-select-row/schema-select-row.component';
import { SchemaTableHeaderComponent } from './integrations/schema/components/schema-table-header/schema-table-header.component';
import { DataSchemaFieldDialogComponent } from './integrations/schema/data-schema/data-schema-field-dialog/data-schema-field-dialog.component';
import { DataSchemaComponent } from './integrations/schema/data-schema/data-schema.component';
import { GraphqlSchemaComponent } from './integrations/schema/graphql-schema/graphql-schema.component';
import { SchemaComponent } from './integrations/schema/schema.component';
import { OnboardingApplicationComponent } from './onboarding/onboarding-application/onboarding-application.component';
import { OnboardingDoneComponent } from './onboarding/onboarding-done/onboarding-done.component';
import { OnboardingGettingStartedComponent } from './onboarding/onboarding-getting-started/onboarding-getting-started.component';
import { OnboardingWelcomeComponent } from './onboarding/onboarding-welcome/onboarding-welcome.component';
import { OnboardingWidgetGettingStartedComponent } from './onboarding/onboarding-widget-getting-started/onboarding-widget-getting-started.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { BillingPlansDialogComponent } from './organization/organization-billing/billing-plans-dialog/billing-plans-dialog.component';
import { OrganizationBillingComponent } from './organization/organization-billing/organization-billing.component';
import { SavePaymentDetailsDialogComponent } from './organization/organization-billing/save-payment-details-dialog/save-payment-details-dialog.component';
import { OrganizationDetailsComponent } from './organization/organization-details/organization-details.component';
import { OrganizationMembersComponent } from './organization/organization-members/organization-members.component';
import { QuotasComponent } from '@squidcloud/console-web/app/usage/quotas/quotas.component';
import { OrganizationComponent } from './organization/organization.component';
import { OrganizationService } from './organization/organization.service';
import { QuickStartFlyOutComponent } from './quick-start/quick-start-fly-out/quick-start-fly-out.component';
import { QuickStartTaskComponent } from './quick-start/quick-start-task/quick-start-task.component';
import { QuickStartService } from './quick-start/quick-start.service';
import { isAuthIntegration } from '@squidcloud/internal-common/types/integrations/schemas';
import { DbBrowserComponent } from './db-browser/db-browser.component';
import { DbBrowserRowComponent } from './db-browser/db-browser-row/db-browser-row.component';
import { DbBrowserFilterDialogComponent } from './db-browser/db-browser-filter-dialog/db-browser-filter-dialog.component';
import { DbBrowserFilterBubbleComponent } from './db-browser/db-browser-filter-bubble/db-browser-filter-bubble.component';
import { AccountService } from '@squidcloud/console-web/app/account/account.service';
import { ContactUsDialogComponent } from './global/components/contact-us-dialog/contact-us-dialog.component';
import { IpAddressesDocComponent } from './application/ip-addresses-doc/ip-addresses-doc.component';
import { DocumentationDialogComponent } from './application/documentation-dialog/documentation-dialog.component';
import { ApplicationUsageComponent } from './application/application-usage/application-usage.component';
import { LoadingIndicatorOverlayComponent } from './global/components/loading-state-wrapper/loading-indicator-overlay.component';
import { OnboardingEntryComponent } from './onboarding/onboarding-entry/onboarding-entry.component';
import { MagicFormComponent } from './global/components/magic-form/magic-form.component';
import { OnboardingCardsComponent } from './onboarding/onboarding-cards/onboarding-cards.component';
import { SeabedComponent } from './global/components/seabed/seabed.component';
import { OnboardingStepComponent } from './onboarding/onboarding-step/onboarding-step.component';
import { JsonEditorComponent } from '@squidcloud/console-web/app/db-browser/json-editor.component';
import { ChartLazyLoadedComponent } from '@squidcloud/console-web/app/metric/chart/chart-lazy-loaded.component';
import { TemplateTypeGuardDirective } from '@squidcloud/console-web/app/global/directives/template-type-guard.directive';
import { captureMarketplaceParametersOnAppInit } from '@squidcloud/console-web/app/utils/marketplace-web-utils';
import { provideHttpClient } from '@angular/common/http';
import { ConsoleCommonModule } from '@squidcloud/console-web/app/common/console-common.module';
import { StorylaneDialogComponent } from './global/components/storylane-dialog/storylane-dialog.component';
import { CarouselComponent } from './global/components/carousel/carousel.component';
import { HowToUseComponent } from './integrations/how-to-use/how-to-use.component';
import { disableTimestampsInLog, enableDebugLogs } from '@squidcloud/internal-common/utils/global.utils';
import { createJsonStringifyPipe, installConsoleOverrides } from 'logpipes';
import { isPlaywrightTestMode } from '@squidcloud/internal-common/utils/e2e-test-utils';
import { OrganizationContactInfoComponent } from './organization/organization-contact-info/organization-contact-info.component';
import { MissedOrganizationDetailsDialogComponent } from './global/components/missed-organization-details-dialog/missed-organization-details-dialog.component';
import { MatSortModule } from '@angular/material/sort';

function getSquid(ngZone: NgZone, auth0Service: Auth0Service): Squid {
  return new Squid({
    appId: environment.squidAppId,
    messageNotificationWrapper: ngZone.run.bind(ngZone),
    region: environment.squidRegion,
    environmentId: environment.squidEnvironmentId,
    squidDeveloperId: environment.squidDeveloperId,
    authProvider: {
      integrationId: 'auth0',
      getToken: async (): Promise<string | undefined> => {
        // Wait until the authentication state is resolved by Auth0.
        const user = await firstValueFrom(auth0Service.user$);
        if (!user) {
          return undefined;
        }
        try {
          // Try to query an access token without user interaction (silently).
          // This method should never fail.
          return await firstValueFrom(auth0Service.getAccessTokenSilently());
        } catch (e) {
          // If 'getAccessTokenSilently' is failed, it is either a network or a misconfiguration
          // (configuration change) issue.
          // In this case, log out the user: this way the user will be forced to use a UI login form.
          if ((e as Error)?.message?.includes('Login required')) {
            console.warn('Cached Auth0 token is expired. Forcing a new sign in.');
          } else {
            console.error(`Can't get Auth token, logging out.`, e);
          }
          auth0Service.logout({ logoutParams: { returnTo: window.location.origin } });
          return undefined;
        }
      },
    },
  });
}

@NgModule({
  declarations: [
    ActionComponent,
    AiChatbotProfilesComponent,
    AiTestChatFlyOutComponent,
    ApiSchemaComponent,
    AppComponent,
    ApplicationOverviewComponent,
    ApplicationSettingsComponent,
    AutofocusDirective,
    AvailableIntegrationsComponent,
    BackendComponent,
    BillingPlansDialogComponent,
    BundleDataTableComponent,
    ChartLazyLoadedComponent,
    ComingSoonComponent,
    ContactUsDialogComponent,
    CopyCodeComponent,
    CreateApplicationDialogComponent,
    CreateApplicationFormComponent,
    CreateEnvFileDocComponent,
    CreateSecretDialogComponent,
    CurrentIntegrationsComponent,
    DataSchemaComponent,
    DataSchemaFieldDialogComponent,
    DbBrowserComponent,
    DbBrowserFilterBubbleComponent,
    DbBrowserFilterDialogComponent,
    DbBrowserRowComponent,
    DeployBackendDocComponent,
    FileUploadComponent,
    DialogWithFormComponent,
    DocComponent,
    DocumentationWithComponentDialogComponent,
    EmailVerificationComponent,
    EmbedWidgetDialogComponent,
    ExecutablesComponent,
    ExecutablesTutorialComponent,
    FeedbackDialogComponent,
    GraphqlSchemaComponent,
    HeaderComponent,
    IllustrationComponent,
    InfoComponent,
    InitializeBackendTutorialComponent,
    InjectionTableComponent,
    InjectionTableFormComponent,
    InputCardComponent,
    IntegrationComponent,
    IntegrationFormComponent,
    IntegrationHeaderComponent,
    IntegrationIdComponent,
    IntegrationsComponent,
    IpAddressesDocComponent,
    LabelWithPrefixDirective,
    LogEntryDialogComponent,
    LogLevelComponent,
    LogoutComponent,
    LogsComponent,
    MetricsComponent,
    MinRolePipe,
    MiniBackendFunctionsComponent,
    MiniBundleDataTableComponent,
    NgVarDirective,
    NoApplicationZeroStateComponent,
    NothingToSeeComponent,
    ObjectEntriesPipe,
    ObjectKeysPipe,
    ObjectValuesPipe,
    OnDestroyComponent,
    OnboardingApplicationComponent,
    OnboardingComponent,
    OnboardingDoneComponent,
    OnboardingGettingStartedComponent,
    OnboardingWelcomeComponent,
    OnboardingWidgetGettingStartedComponent,
    OpenApiComponent,
    OpenApiTutorialComponent,
    OrganizationBillingComponent,
    OrganizationBillsListComponent,
    OrganizationComponent,
    OrganizationDetailsComponent,
    OrganizationMembersComponent,
    QuotasComponent,
    PageZeroStateComponent,
    ParseAppIdPipe,
    PortalComponent,
    ProfileSettingsComponent,
    ProtectedLayoutComponent,
    QuickStartFlyOutComponent,
    QuickStartTaskComponent,
    QuotedActionDialogComponent,
    ReactGraphiqlLazyLoadedComponent,
    RudderChatComponent,
    SavePaymentDetailsDialogComponent,
    SchedulersComponent,
    SchedulersTutorialComponent,
    SchemaCardComponent,
    SchemaCardItemComponent,
    SchemaComponent,
    SchemaFieldMenuComponent,
    SchemaSelectRowComponent,
    SchemaTableHeaderComponent,
    SecretsComponent,
    SecurityRulesComponent,
    SecurityRulesTutorialComponent,
    SelectSecretComponent,
    ShowEnvVarsDocComponent,
    SidebarLayoutComponent,
    SortPipe,
    StatusPageComponent,
    TriggersComponent,
    TriggersTutorialComponent,
    UpdateSecretDialogComponent,
    WebhooksComponent,
    WebhooksTutorialComponent,
    WidgetContactComponent,
    DocumentationDialogComponent,
    ApplicationUsageComponent,
    LoadingIndicatorOverlayComponent,
    OnboardingEntryComponent,
    MagicFormComponent,
    OnboardingCardsComponent,
    SeabedComponent,
    OnboardingStepComponent,
    JsonEditorComponent,
    StorylaneDialogComponent,
    CarouselComponent,
    HowToUseComponent,
    OrganizationContactInfoComponent,
    MissedOrganizationDetailsDialogComponent,
  ],
  imports: [
    AppRoutingModule,
    Auth0Module.forRoot({
      domain: environment.auth0.customDomain,
      clientId: environment.auth0.clientId,

      // The 'localstorage' will keep ID Token and Access Tokens (one per audience).
      // This cache will prevent extra HTTP request to Auth0 endpoint on page reload.
      //
      // The ID Token will only be renewed if one of the Access Tokens is renewed.
      //
      // The Access Token will be renewed when is asked by a user
      // (getAccessTokenSilently), and the cached Access Token is expired or is within 60
      // seconds from the expiration time.
      cacheLocation: 'localstorage',

      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: 'console-backend-api-for-web', // Hardcoded: same for all envs.
      },
    }),
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    ConsoleCommonModule,
    FormsModule,
    MarkdownModule.forRoot(),
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    NgOptimizedImage,
    PopoverModule,
    ReactiveFormsModule,
    RecaptchaFormsModule,
    RecaptchaModule,
    SegmentModule.forRoot({
      apiKey: environment.segmentWriteKey,
      debug: false,
      loadOnInitialization: !!environment.segmentWriteKey,
      // Do not change until this bug is not fixed & update is released:
      // https://github.com/opendecide/ngx-segment-analytics/issues/192.
      segmentHost: `https://${SEGMENTS_DEFAULT_CONFIG.segmentHost}`,
    }),
    TextFieldModule,
    TemplateTypeGuardDirective,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    provideHttpClient(),
    { provide: Squid, useFactory: getSquid, deps: [NgZone, Auth0Service] },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptchaSiteKey,
      } as RecaptchaSettings,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // noinspection JSUnusedLocalSymbols
  /** The services here are injected just so their constructor will run when the app loads. */
  constructor(
    private readonly organizationService: OrganizationService,
    squid: Squid,
    private readonly applicationService: ApplicationService,
    private readonly themeService: ThemeService,
    private readonly quickStartService: QuickStartService,
    accountService: AccountService,
  ) {
    if (isPlaywrightTestMode()) {
      enableDebugLogs();
      disableTimestampsInLog(); // Playwright already logs timestamps for all messages.
      // To see object details (like Map/Set) logged into console in Playwright we need a formatter.
      // Otherwise, we will see something like this:  'ClientRequestId to local documents: Map(4)'.
      installConsoleOverrides(
        createJsonStringifyPipe({
          levelPropertyName: null,
          timestampPropertyName: null,
          messageIdPropertyName: null,
        }),
      );
    }

    console.debug('Starting Squid Console application');
    if (environment.datadogConfig.logs) {
      datadogLogs.init(environment.datadogConfig.logs);
    }
    this.themeService.initialize();
    this.maybeMarkQuickStartTasksAsComplete().then();
    (window as unknown as { squid: Squid }).squid = squid;
    (window as unknown as { squidConsole: Record<string, object> }).squidConsole = {
      organizationService: this.organizationService,
      applicationService: this.applicationService,
      accountService,
      environment,
    };
  }

  private async maybeMarkQuickStartTasksAsComplete(): Promise<void> {
    this.maybeMarkCreateOrgTaskAsComplete().then();
    this.maybeMarkCreateAppTaskAsComplete().then();
    this.maybeMarkInitializeBackendTaskAsComplete();
    this.maybeMarkAuthProviderTaskAsComplete();
    this.maybeMarkSecurityRulesTaskAsComplete();
  }

  private async maybeMarkCreateOrgTaskAsComplete(): Promise<void> {
    await firstValueFrom(this.organizationService.observeCurrentOrganization().pipe(filter(Boolean), take(1)));
    this.quickStartService.markTaskComplete('createOrganization').then();
  }

  private async maybeMarkCreateAppTaskAsComplete(): Promise<void> {
    await firstValueFrom(this.applicationService.observeCurrentApplication().pipe(filter(Boolean), take(1)));
    this.quickStartService.markTaskComplete('createApplication').then();
  }

  private maybeMarkInitializeBackendTaskAsComplete(): void {
    this.observeAllApplications().subscribe(apps => {
      const app = apps.find(app => app.backendInitialized);
      if (app) {
        this.quickStartService.markTaskComplete('backendInitialized').then();
      }
    });
  }

  private maybeMarkAuthProviderTaskAsComplete(): void {
    this.observeAllApplications().subscribe(apps => {
      const appWithAuthIntegration = apps.find(app =>
        Object.values(app.integrations || {}).some(i => isAuthIntegration(i)),
      );

      if (appWithAuthIntegration) {
        this.quickStartService.markTaskComplete('connectedAuthenticationProvider').then();
      }
    });
  }

  private maybeMarkSecurityRulesTaskAsComplete(): void {
    this.observeAllApplications().subscribe(apps => {
      const app = apps.find(app => {
        const bundleData = app.bundleData;
        if (!bundleData) return false;
        return Object.values(bundleData.databases || {}).some(db => {
          const topLevelSecurityRules = Object.values(db.security || {}).length > 0;
          const collectionLevelSecurityRules = Object.values(db.collections || {}).some(collection => {
            const collectionSecurityRules = Object.values(collection.security || {}).length > 0;
            return topLevelSecurityRules || collectionSecurityRules;
          });
          return topLevelSecurityRules || collectionLevelSecurityRules;
        });
      });

      if (app) {
        this.quickStartService.markTaskComplete('secureYourData').then();
      }
    });
  }

  private observeAllApplications(): Observable<CpApplication[]> {
    return this.applicationService.observeAllApplications().pipe(filter(apps => apps.length > 0));
  }
}

runBeforeAngularRequestHandlers();

/**
 * Processes request parameters before Angular kicks in.
 * Guaranteed to be called before any redirects triggered by Angular guards & services (like Auth0 guard).
 */
function runBeforeAngularRequestHandlers(): void {
  captureMarketplaceParametersOnAppInit();
}
