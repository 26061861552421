import {
  Auth0IntegrationConfig,
  CognitoIntegrationConfig,
  DescopeIntegrationConfig,
  FirebaseAuthIntegrationConfig,
  JwtHmacIntegrationConfig,
  JwtRsaIntegrationConfig,
  OktaIntegrationConfig,
} from './auth.types';
import {
  BigQueryIntegrationConfig,
  ClickHouseIntegrationConfig,
  CockroachIntegrationConfig,
  IntegrationDataSchema,
  InternalIntegrationConfig,
  MongoIntegrationConfig,
  MssqlIntegrationConfig,
  MySqlIntegrationConfig,
  OracleIntegrationConfig,
  PostgresIntegrationConfig,
  SnowflakeIntegrationConfig,
} from './database.types';
import { DatadogIntegrationConfig, NewRelicIntegrationConfig } from './observability.types';
import { IntegrationSchemaType, IntegrationType } from '../../public-types/integration.public-types';
import { BuiltInQueueIntegrationConfig, ConfluentIntegrationConfig, KafkaIntegrationConfig } from './queue-types';
import { IntegrationId } from '../../public-types/communication.public-types';
import { AiChatbotIntegrationConfig } from './ai_chatbot.types';
import {
  GraphQLIntegrationConfig,
  HttpApiIntegrationConfig,
  IntegrationApiSchema,
  IntegrationGraphQLSchema,
} from './api.types';
import {
  BuiltInGcsIntegrationConfig,
  BuiltInS3IntegrationConfig,
  GcsIntegrationConfig,
  GoogleDriveIntegrationConfig,
  S3IntegrationConfig,
} from './storage-types';

/** @internal. */
export interface IntegrationConfigTypes {
  [IntegrationType.built_in_db]: InternalIntegrationConfig;
  [IntegrationType.built_in_s3]: BuiltInS3IntegrationConfig;
  [IntegrationType.built_in_gcs]: BuiltInGcsIntegrationConfig;
  [IntegrationType.s3]: S3IntegrationConfig;
  [IntegrationType.gcs]: GcsIntegrationConfig;
  [IntegrationType.google_drive]: GoogleDriveIntegrationConfig;
  [IntegrationType.mongo]: MongoIntegrationConfig;
  [IntegrationType.mysql]: MySqlIntegrationConfig;
  [IntegrationType.clickhouse]: ClickHouseIntegrationConfig;
  [IntegrationType.bigquery]: BigQueryIntegrationConfig;
  [IntegrationType.oracledb]: OracleIntegrationConfig;
  [IntegrationType.mssql]: MssqlIntegrationConfig;
  [IntegrationType.postgres]: PostgresIntegrationConfig;
  [IntegrationType.cockroach]: CockroachIntegrationConfig;
  [IntegrationType.snowflake]: SnowflakeIntegrationConfig;
  [IntegrationType.api]: HttpApiIntegrationConfig;
  [IntegrationType.graphql]: GraphQLIntegrationConfig;
  [IntegrationType.datadog]: DatadogIntegrationConfig;
  [IntegrationType.newrelic]: NewRelicIntegrationConfig;
  [IntegrationType.auth0]: Auth0IntegrationConfig;
  [IntegrationType.jwt_rsa]: JwtRsaIntegrationConfig;
  [IntegrationType.jwt_hmac]: JwtHmacIntegrationConfig;
  [IntegrationType.ai_chatbot]: AiChatbotIntegrationConfig;
  [IntegrationType.cognito]: CognitoIntegrationConfig;
  [IntegrationType.okta]: OktaIntegrationConfig;
  [IntegrationType.kafka]: KafkaIntegrationConfig;
  [IntegrationType.confluent]: ConfluentIntegrationConfig;
  [IntegrationType.built_in_queue]: BuiltInQueueIntegrationConfig;
  [IntegrationType.descope]: DescopeIntegrationConfig;
  [IntegrationType.firebase_auth]: FirebaseAuthIntegrationConfig;
}

/** @internal. */
export interface IntegrationSchemaTypes {
  [IntegrationType.built_in_db]: IntegrationDataSchema;
  [IntegrationType.mongo]: IntegrationDataSchema;
  [IntegrationType.mysql]: IntegrationDataSchema;
  [IntegrationType.clickhouse]: IntegrationDataSchema;
  [IntegrationType.oracledb]: IntegrationDataSchema;
  [IntegrationType.bigquery]: IntegrationDataSchema;
  [IntegrationType.mssql]: IntegrationDataSchema;
  [IntegrationType.postgres]: IntegrationDataSchema;
  [IntegrationType.cockroach]: IntegrationDataSchema;
  [IntegrationType.snowflake]: IntegrationDataSchema;
  [IntegrationType.api]: IntegrationApiSchema;
  [IntegrationType.graphql]: IntegrationGraphQLSchema;
}

/** @internal. */
export type IntegrationTypeWithConfig = keyof IntegrationConfigTypes;
/** @internal. */
export type IntegrationSchemaKeys = keyof IntegrationSchemaTypes;

/** @internal. */
export type IntegrationConfig = IntegrationConfigTypes[IntegrationTypeWithConfig];
/** @internal. */
export type IntegrationSchema = IntegrationSchemaTypes[IntegrationSchemaKeys];

/** @internal. */
export const DatabaseIntegrationTypes = [
  IntegrationType.built_in_db,
  IntegrationType.mongo,
  IntegrationType.mysql,
  IntegrationType.clickhouse,
  IntegrationType.bigquery,
  IntegrationType.mssql,
  IntegrationType.postgres,
  IntegrationType.cockroach,
  IntegrationType.snowflake,
  IntegrationType.oracledb,
] as const;

/** @internal. */
export type DatabaseIntegrationType = (typeof DatabaseIntegrationTypes)[number];
/** @internal. */
export type DatabaseIntegrationConfig = IntegrationConfigTypes[DatabaseIntegrationType];

/** @internal. */
export const AuthIntegrationTypes = [
  IntegrationType.auth0,
  IntegrationType.jwt_rsa,
  IntegrationType.jwt_hmac,
  IntegrationType.cognito,
  IntegrationType.okta,
  IntegrationType.descope,
  IntegrationType.firebase_auth,
] as const;

/** @internal. */
export type AuthIntegrationType = (typeof AuthIntegrationTypes)[number];
/** @internal. */
export type AuthIntegrationConfig = IntegrationConfigTypes[AuthIntegrationType];

/** @internal. */
export function isDataIntegrationType(type: unknown): type is DatabaseIntegrationType {
  return DatabaseIntegrationTypes.includes(type as DatabaseIntegrationType);
}

/** @internal. */
export function isAuthIntegrationType(type: unknown): type is AuthIntegrationType {
  return (AuthIntegrationTypes as readonly IntegrationType[]).includes(type as IntegrationType);
}

/** @internal. */
export function isAuthIntegration(integration: unknown): integration is AuthIntegrationConfig {
  return isAuthIntegrationType((integration as AuthIntegrationConfig)?.type);
}

/** @internal. */
export interface BaseIntegrationConfig {
  id: IntegrationId;
  type: IntegrationType;
  creationDate?: Date;
  updateDate?: Date;
}

/** @internal. */
export interface BaseIntegrationSchema {
  type: IntegrationSchemaType;
}

/** @internal. */
export function isNoSqlDatabase(type: IntegrationType): boolean {
  return type === IntegrationType.mongo || type === IntegrationType.built_in_db;
}
