import { generateShortId } from '@squidcloud/internal-common/public-utils/id-utils';
import { assertString, objectAssertion } from 'assertic';

export interface AwsMpCustomerData {
  /** Product code. Resolved by a call to AWS using `x-amzn-marketplace-token`. */
  productCode: string;

  /** AWS customer id. Resolved by a call to AWS  using `x-amzn-marketplace-token`. */
  customerId: string;

  /** AWS customer account id. Resolved by a call to AWS  using `x-amzn-marketplace-token`. */
  customerAwsAccountId: string;
}

/**
 * An event stored in Squid database every time user clicks
 * 'Click here to set up your account' on AWS product website.
 *
 * Contains all information passed by AWS to Squid.
 */
export interface AwsMpAccountSetupEvent extends AwsMpCustomerData {
  /** Unique record id. */
  id: string;

  /** The timestamp of the click. */
  clickDate: Date;
}

export function generateAwsMpAccountSetupEventId(): string {
  return `aws-account-setup-${generateShortId()}`;
}

/** URL parameter passed by the webhook as a part of the redirect to the console.  */
export const AWS_MP_ACCOUNT_SETUP_EVENT_ID_URL_PARAMETER = 'awsAccountSetupEventId';

/**
 * Notification sent to 'aws-mp-entitlement-notification' SNS topic.
 *
 * This topic notifies when buyers create a new contract, upgrade it, renew it, or it expires.
 * This is only available for products with pricing models that include a contract
 * (also known as SaaS Contracts and SaaS Contracts with Consumption (Overages)).
 *
 * For entitlement messages, regardless of the action (new, upgrade, renewal, or expired), the message is the same.
 * A subsequent call to GetEntitlement is required to discover the content of the update.
 *
 * https://docs.aws.amazon.com/marketplace/latest/userguide/saas-notification.html#saas-sns-message-body.
 */
export interface AwsMpEntitlementNotification {
  /** Always is 'entitlement-updated'. */
  action: 'entitlement-updated';
  'customer-identifier': string;
  'product-code': string;
}

export const AWS_MP_SUBSCRIPTION_ACTION_TYPES = [
  /** The subscribe-success message signals when the seller can begin sending metering records. */
  'subscribe-success',

  /**
   * If the subscribe-fail message is generated, payment might have failed even though the buyer has already
   * transitioned from the AWS Marketplace to the seller's SaaS landing page.
   * The seller should wait for the subscribe-success message before allowing consumption of the product.
   */
  'subscribe-fail',

  /**
   * When a buyer unsubscribes, an unsubscribe-pending message is sent first.
   * This indicates that the seller has a limited time (about one hour) to get final metering records
   * sent before the buyer is cancelled completely.
   */
  'unsubscribe-pending',

  /**
   * The unsubscribe-success message signals the completion of cancellation,
   * after which no further metering records will be accepted.
   */
  'unsubscribe-success',
] as const;

export type AwsMpSubscriptionActionType = (typeof AWS_MP_SUBSCRIPTION_ACTION_TYPES)[number];

/**
 * Notification sent to 'aws-mp-subscription-notification' SNS topic.
 * https://docs.aws.amazon.com/marketplace/latest/userguide/saas-notification.html#saas-sns-subscription-message-body.
 */
export interface AwsMpSubscriptionNotification {
  action: AwsMpSubscriptionActionType;
  'customer-identifier': string;
  'product-code': string;
  /** The offer-identifier only appears in the notification if the offer is a private offer. */
  'offer-identifier'?: string;
  /**
   * Indicates if the buyer's subscription is a free trial.
   * The JSON value of this property is not a boolean datatype.
   * Instead, the value is converted to a string datatype: "true" or "false".
   */
  isFreeTrialTermPresent: string;
}

export type AwsMpNotificationEvent = AwsMpEntitlementNotification | AwsMpSubscriptionNotification;

export interface ActivateAwsPurchaseRequest {
  awsMpAccountSetupEventId: string;
}

export const activateAwsPurchaseRequestAssertion = objectAssertion<ActivateAwsPurchaseRequest>({
  awsMpAccountSetupEventId: assertString,
});

export interface CreateAwsBillingEntityIdInput extends AwsMpCustomerData {
  privateOfferId?: string;
}

export function createAwsBillingEntityId(input: CreateAwsBillingEntityIdInput): string {
  let result = `${input.productCode}|${input.customerId}|${input.customerAwsAccountId}`;
  if (input.privateOfferId) {
    result += '|' + input.privateOfferId;
  }
  return result;
}
