import { MILLIS_PER_HOUR, MILLIS_PER_MINUTE, MILLIS_PER_SECOND } from '@squidcloud/internal-common/types/time-units';

export function formatAsInteger(number: number): string {
  if (!number) return '0';
  return number.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
}

export function formatAsNumber(number: number): string {
  if (!number) return '0';
  if (Number.isInteger(number) || Math.abs(number) < 1) {
    return number.toLocaleString('en-US');
  } else {
    const digits = Math.abs(number) < 10 ? 2 : Math.abs(number) < 100 ? 1 : 0;
    return number.toLocaleString('en-US', { minimumFractionDigits: digits, maximumFractionDigits: digits });
  }
}

export function formatAsTimePeriod(millis: number): string {
  if (millis < MILLIS_PER_SECOND) {
    return `${millis.toFixed(0)}ms`;
  } else if (millis < MILLIS_PER_MINUTE) {
    const seconds = millis / MILLIS_PER_SECOND;
    return `${seconds.toFixed(2)}s`;
  } else if (millis < MILLIS_PER_HOUR) {
    const minutes = millis / MILLIS_PER_MINUTE;
    return `${minutes.toFixed(2)}min`;
  } else {
    const hours = millis / MILLIS_PER_HOUR;
    return `${hours.toFixed(2)}h`;
  }
}

export function trimNumber(num: number, fractionDigits = 2): number {
  if (!num) return 0;
  if (Math.floor(num) !== num) {
    return parseFloat(num.toFixed(fractionDigits));
  } else {
    return num;
  }
}
