import { ChangeDetectionStrategy, Component } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  BundleDataTableColumn,
  BundleDataTableData,
  BundleDataTableRow,
} from '../../global/components/bundle-data-table/bundle-data.types';
import { OnDestroyComponent } from '../../global/components/on-destroy/on-destroy.component';
import { BackendService } from '@squidcloud/console-web/app/backend/backend.service';
import { ApplicationService } from '@squidcloud/console-web/app/application/application.service';
import { CpApplication } from '@squidcloud/console-common/types/application.types';
import { convertToSquidRegion } from '@squidcloud/console-common/clouds-and-regions';
import { environment } from '@squidcloud/console-web/environments/environment';
import { getApplicationUrl } from '@squidcloud/internal-common/utils/http';

@Component({
  selector: 'app-openapi',
  templateUrl: './openapi.component.html',
  styleUrls: ['./openapi.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpenApiComponent extends OnDestroyComponent {
  readonly bundleDataTableDataObs: Observable<BundleDataTableData | undefined>;
  readonly applicationObs = this.applicationService.observeCurrentApplication();

  constructor(
    private readonly applicationService: ApplicationService,
    private readonly backendService: BackendService,
  ) {
    super();
    this.backendService.reportBackendMetadataAvailable(false);

    this.bundleDataTableDataObs = this.backendService.getMetricsObs(this.onDestroy).pipe(
      map(metricsResponse => {
        const metrics = metricsResponse.functionsExecutionCounts?.['openapi'];
        if (!metrics) return undefined;
        const rows: Array<BundleDataTableRow> = metrics.functions
          .sort((m1, m2) => {
            const extras1 = m1.functionData.extras;
            const extras2 = m2.functionData.extras;
            const url1 = extras1['url'] as string;
            const url2 = extras2['url'] as string;
            let result = url1.localeCompare(url2);
            if (result === 0) {
              const httpMethod1 = extras1['method'] as string;
              const httpMethod2 = extras2['method'] as string;
              result = httpMethod1.localeCompare(httpMethod2);
            }
            return result;
          })
          .map(metric => {
            const functionData = metric.functionData;
            const counts = metric.counts;
            const extras = functionData.extras;
            const httpMethod = (extras['method'] as string).toUpperCase();
            const functionName = functionData.serviceFunctionName as string;
            const url = extras['url'] as string;
            const urlPathStartIdx = url.indexOf('/openapi/');
            const openapiPath = url.substring(urlPathStartIdx);
            const columns: Array<BundleDataTableColumn> = [
              {
                value: openapiPath,
                link: url,
                tooltip: url,
                target: '_blank',
              },
              { value: httpMethod },
              { value: `${counts.success}`, sortValue: counts.success },
              {
                value: `${counts.errors}`,
                sortValue: counts.errors,
                class: counts.errors > 0 ? 'error' : 'muted',
              },
              { value: functionName },
            ];
            return { columns, link: '/someLink' };
          });

        this.backendService.reportBackendMetadataAvailable(!!rows.length);
        if (!rows.length) return undefined;

        this.backendService.reportBackendMetadataAvailable(!!rows.length);
        if (!rows.length) return undefined;

        return {
          headerRow: ['URL', 'Method', 'Executions', 'Errors', 'Function'],
          rows,
          metrics: metrics.charts,
        };
      }),
    );
  }

  getOpenApiBaseUrl(application: CpApplication): string {
    const regionPrefix = convertToSquidRegion(
      application.cloudId,
      application.region,
      application.shard,
      environment.stage,
    );
    return getApplicationUrl(regionPrefix, application.appId, 'openapi');
  }
}
