import { Injectable } from '@angular/core';
import { OrganizationService } from './organization.service';
import { QuotaService } from '@squidcloud/console-web/app/organization/quota-service';
import { MatDialog } from '@angular/material/dialog';
import { QuotedActionDialogComponent } from '@squidcloud/console-web/app/global/components/quoted-action-dialog/quoted-action-dialog.component';
import { MissedOrganizationDetailsDialogComponent } from '@squidcloud/console-web/app/global/components/missed-organization-details-dialog/missed-organization-details-dialog.component';
import { isMarketplaceBillingType } from '@squidcloud/console-common/types/billing.types';

/**
 * Implements complex permissions for certain operations based on different aspects:
 * quotas, organization details requirements, etc.
 */
@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor(
    private readonly organizationService: OrganizationService,
    private readonly quotaService: QuotaService,
    private readonly dialog: MatDialog,
  ) {}

  /**
   * Checks if an application can be created in the current context: current user, current organization or billing plan.
   * If no application can't be created returns false, otherwise returns true.
   * The negative result will trigger a UI notification to user unless disabled via options.
   */
  async checkCanCreateApplication(options: CheckCanCreateApplicationOptions = {}): Promise<boolean> {
    const failedByQuotaCheck = !(await this.quotaService.checkCanCreateApplication());
    if (failedByQuotaCheck) {
      if (!options.disableActionOnFailedCheck) {
        QuotedActionDialogComponent.show(this.dialog, { quotaName: 'maxNumberOfApplications' });
      }
      return false;
    }
    const organization = this.organizationService.getCurrentOrganizationOrFail();
    const failedByMarketplaceOrgDetailsCheck =
      isMarketplaceBillingType(organization.billingType) && !organization.contactInfo;
    if (failedByMarketplaceOrgDetailsCheck) {
      if (!options.disableActionOnFailedCheck) {
        MissedOrganizationDetailsDialogComponent.show(this.dialog);
      }
      return false;
    }
    return true;
  }
}

export interface CheckCanCreateApplicationOptions {
  /**
   * When set to 'true' 'checkCanCreateApplication' will not trigger
   * any UI notification to user if the check result is negative.
   */
  disableActionOnFailedCheck?: boolean;
}
