import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { AnalyticsService } from '@squidcloud/console-web/app/analytics/analytics.service';
import { appIdWithEnvironmentId } from '@squidcloud/internal-common/types/communication.types';
import { getMessageFromError } from '@squidcloud/internal-common/utils/error-utils';
import { truthy } from 'assertic';
import { firstValueFrom } from 'rxjs';
import { SnackBarService } from '../../global/services/snack-bar.service';
import { ApplicationService } from '../application.service';
import { CreateApplicationFormComponent } from '../create-application-form/create-application-form.component';
import { PermissionsService } from '@squidcloud/console-web/app/organization/permissions.service';

@Component({
  selector: 'app-create-application-dialog',
  templateUrl: './create-application-dialog.component.html',
  styleUrls: ['./create-application-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateApplicationDialogComponent {
  @ViewChild(CreateApplicationFormComponent)
  readonly createApplicationFormComponent?: CreateApplicationFormComponent;
  serverCallInProgress = false;

  constructor(
    private readonly applicationService: ApplicationService,
    private readonly cdr: ChangeDetectorRef,
    private readonly dialogRef: MatDialogRef<CreateApplicationDialogComponent>,
    private readonly snackbarService: SnackBarService,
    private readonly permissionsService: PermissionsService,
    private readonly dialog: MatDialog,
    private readonly snackBar: SnackBarService,
    private readonly analytics: AnalyticsService,
  ) {}

  async submitCreateApplication(): Promise<void> {
    if (!(await this.permissionsService.checkCanCreateApplication())) {
      this.dialogRef.close();
      return;
    }

    void this.analytics.track('CTA Clicked', {
      title: 'Create',
      location: 'create-application-dialog',
      appId: undefined,
    });

    const formDetails = truthy(this.createApplicationFormComponent?.getFormDetails(), 'NO_FORM_DETAILS');
    const appName = formDetails.name;
    const appsInOrg = await firstValueFrom(this.applicationService.observeApplicationsForCurrentOrganization());
    if (appsInOrg?.find(app => app.name.toLowerCase().trim() === appName.toLowerCase().trim())) {
      this.snackBar.warning('An application with this name already exists in your organization');
      return;
    }

    try {
      this.serverCallInProgress = true;
      const region = formDetails.region;
      const appId = await this.applicationService.createApplication(appName, region);
      this.snackbarService.success('Application created');
      this.dialogRef.close();
      await this.applicationService.switchApplicationUrl(appIdWithEnvironmentId(appId, 'dev'));
    } catch (error) {
      console.error('Failed to create application', error);
      this.snackbarService.warning(
        `Unable to create application, please try again later. ${getMessageFromError(error)}`,
      );
    } finally {
      this.serverCallInProgress = false;
      this.cdr.markForCheck();
    }
  }

  static show(dialog: MatDialog): MatDialogRef<CreateApplicationDialogComponent> {
    const config: MatDialogConfig = {
      maxWidth: '689px',
      width: '100%',
      autoFocus: true,
      restoreFocus: false,
      panelClass: 'modal',
    };
    return dialog.open(CreateApplicationDialogComponent, config);
  }
}
