<div class="contact_info_tab">
  <div>
    <h3>Organization Contact Info</h3>
  </div>
  <div class="contact_info_form">
    @if (organizationService.currentOrganization$ | async; as organization) {
      <magic-form [data]="formConfig"></magic-form>
    }
  </div>
</div>
