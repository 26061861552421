@if (widgetParamsObs | async; as widgetParams) {
  <div [ngClass]="minimized ? 'minimized_display' : 'block_display'">
    <div
      class="test_chat_container"
      *ngVar="testChatActiveObs | async as testChatActive"
      [ngClass]="{ active: testChatActive, ai_query: widgetParams.aiQuery }"
    >
      <div class="header">
        <div class="title">
          {{
            widgetParams.aiQuery
              ? 'Query ' + widgetParams.integrationId
              : 'Test chat'
          }}
        </div>
        <mat-icon
          class="minimize_button"
          svgIcon="minimize_icon"
          (click)="toggleMinimize()"
        ></mat-icon>
        <mat-icon
          class="close_button"
          svgIcon="close_icon"
          (click)="closeTestChat()"
          data-testid="close-test-chat-button"
        ></mat-icon>
        <div
          class="disable_history_toggle_container"
          *ngIf="!widgetParams.aiQuery"
        >
          Keep history
          <mat-slide-toggle
            [(ngModel)]="historyEnabled"
            class="section_toggle_button"
            color="primary"
          >
          </mat-slide-toggle>
        </div>
        <div class="explanation_text" *ngIf="widgetParams.aiQuery">
          For better results, be sure to add descriptions to your collections and
          relevant fields
        </div>
      </div>
      <div class="widget_container">
        <squid-chat-widget
          *ngIf="testChatActive"
          class="squid_chat_widget"
          [intro-text]="widgetParams.introText"
          [header-title]="[widgetParams.profileId || 'SquidAI Chat']"
          [squid-app-id]="widgetParams.appId"
          [squid-region]="widgetParams.region"
          [squid-environment-id]="widgetParams.environmentId"
          [squid-api-key]="widgetParams.apiKey"
          [squid-ai-integration-id]="widgetParams.integrationId"
          [squid-ai-profile-id]="widgetParams.profileId"
          [squid-ai-query]="widgetParams.aiQuery"
          [disable-history]="!historyEnabled"
          [include-reference]="true"
          (change)="onChange($event)"
        >
        </squid-chat-widget>
      </div>
    </div>
  </div>
}