import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { BundleDataTableData, BundleDataTableRow } from './bundle-data.types';
import { LineChart } from '../../../metric/chart/chart.types';
import dayjs from 'dayjs';
import { formatAsInteger, formatAsNumber, formatAsTimePeriod, trimNumber } from '../../utils/formatting-utils';
import { BackendMetricsChartData } from '@squidcloud/console-common/types/metrics.types';
import { range } from '@squidcloud/internal-common/utils/object';
import { Sort } from '@angular/material/sort';

const UTC_OFFSET = dayjs().utcOffset();

@Component({
  selector: 'bundle-data-table',
  templateUrl: './bundle-data-table.component.html',
  styleUrls: ['./bundle-data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BundleDataTableComponent implements OnChanges {
  @Input({ required: true })
  bundleDataTableData!: BundleDataTableData;

  /** Sorted table rows. */
  sortedTableRows: Array<BundleDataTableRow> = [];
  private sort: Sort = { direction: 'asc', active: '' };

  executionsChart?: LineChart;
  latencyChart?: LineChart;
  qpsChart?: LineChart;

  readonly tableRowType: Array<BundleDataTableRow> = [];

  ngOnChanges(): void {
    if (this.bundleDataTableData?.metrics) {
      this.executionsChart = this.convertToExecutionsChart(this.bundleDataTableData.metrics);
      this.latencyChart = this.convertToLatencyChart(this.bundleDataTableData.metrics);
      this.qpsChart = this.convertToQpsChart(this.bundleDataTableData.metrics);
    } else {
      this.executionsChart = undefined;
      this.latencyChart = undefined;
      this.qpsChart = undefined;
    }
    this.sortTableRows(this.sort);
  }

  get columnNames(): string[] {
    return this.bundleDataTableData.headerRow;
  }

  sortTableRows(sort: Sort): void {
    this.sort = sort;
    if (!this.sort.active) {
      this.sort.active = this.bundleDataTableData.headerRow[0];
    }
    if (this.sort.direction === '') {
      // Default sort follows @Input state.
      this.sortedTableRows = this.bundleDataTableData.rows;
      return;
    }
    const activeColumnIndex = Math.max(this.bundleDataTableData.headerRow.indexOf(sort.active), 0);
    this.sortedTableRows = [...this.bundleDataTableData.rows].sort((rowA, rowB) => {
      const cellA = rowA.columns[activeColumnIndex];
      const cellB = rowB.columns[activeColumnIndex];
      const aValue = cellA?.sortValue ?? cellA.value;
      const bValue = cellB?.sortValue ?? cellB.value;
      const comparatorResult =
        typeof aValue === 'number' && typeof bValue === 'number'
          ? aValue - bValue
          : `${aValue}`.localeCompare(`${bValue}`);
      return sort.direction === 'asc' ? comparatorResult : -comparatorResult;
    });
  }

  convertToExecutionsChart(metrics: BackendMetricsChartData): LineChart {
    return {
      type: 'line',
      template: 'summary_on_left',
      summaryData: [
        {
          label: 'Executions',
          value: `${formatAsInteger(metrics.totalExecutions)}`,
          color: 'var(--doc4)',
        },
        {
          label: 'Errors',
          value: `${formatAsInteger(metrics.totalErrors)}`,
          color: 'var(--fail1)',
        },
      ],
      options: {
        legend: false,
        showXAndYAxis: true,
        xAxisTicks: this.buildXAxisTicks(metrics),
      },
      data: [
        {
          name: 'Executions',
          series: metrics.successExecutionCountSeries.map((itemValue, i) => {
            const time = new Date(metrics.timestampSeries[i]).getTime();
            const name = this.getHistogramTimestamp(time);
            return {
              name: name,
              value: trimNumber(itemValue),
              formattedValue: formatAsInteger(itemValue),
            };
          }),
        },
        {
          name: 'Errors',
          series: metrics.errorExecutionCountSeries.map((itemValue, i) => {
            const name = this.getHistogramTimestamp(new Date(metrics.timestampSeries[i]).getTime());
            return {
              name: name,
              value: trimNumber(itemValue),
              formattedValue: formatAsInteger(itemValue),
            };
          }),
        },
      ],
    };
  }

  private buildXAxisTicks(metrics: BackendMetricsChartData): string[] {
    return this.getEvenlySpacedItems(
      metrics.timestampSeries.map(i => {
        const time = new Date(i).getTime();
        return this.getHistogramTimestamp(time);
      }),
      8,
    );
  }

  private getHistogramTimestamp(timestamp: number): string {
    return dayjs(timestamp).add(UTC_OFFSET, 'minutes').format('HH:mm');
  }

  convertToLatencyChart(metrics: BackendMetricsChartData): LineChart {
    return {
      type: 'line',
      template: 'summary_on_top',
      summaryData: [
        {
          label: 'Latency',
          value: formatAsTimePeriod(metrics.averageExecutionTime),
          color: 'var(--doc5)',
        },
      ],
      options: {
        legend: false,
        showXAndYAxis: true,
        xAxisTicks: this.buildXAxisTicks(metrics),
      },
      data: [
        {
          name: 'Latency',
          series: metrics.averageExecutionTimeSeries.map((itemValue, i) => {
            return {
              name: this.getHistogramTimestamp(new Date(metrics.timestampSeries[i]).getTime()),
              value: trimNumber(itemValue),
              formattedValue: formatAsTimePeriod(itemValue),
            };
          }),
        },
      ],
    };
  }

  convertToQpsChart(metrics: BackendMetricsChartData): LineChart {
    return {
      type: 'line',
      template: 'summary_on_top',
      summaryData: [
        {
          label: 'Avg QPS',
          value: formatAsNumber(metrics.averageQps),
          color: 'var(--doc2)',
        },
      ],
      options: {
        legend: false,
        showXAndYAxis: true,
        xAxisTicks: this.buildXAxisTicks(metrics),
      },
      data: [
        {
          name: 'Avg QPS',
          series: metrics.averageQpsSeries.map((itemValue, i) => {
            return {
              name: this.getHistogramTimestamp(new Date(metrics.timestampSeries[i]).getTime()),
              value: trimNumber(itemValue),
              formattedValue: formatAsNumber(itemValue),
            };
          }),
        },
      ],
    };
  }

  private getEvenlySpacedItems<T>(arr: T[], maximumNumberOfItems: number): T[] {
    const n = arr.length;
    let step = 1;
    if (n > maximumNumberOfItems) {
      step = Math.floor(n / (maximumNumberOfItems - 1));
    }
    const indices = range(0, n, step, maximumNumberOfItems);
    const result = indices.map(i => arr[i]);
    if (result[result.length - 1] !== arr[arr.length - 1]) {
      result[result.length - 1] = arr[arr.length - 1];
    }
    return result;
  }

  hasChartData(): boolean {
    const allCharts = [this.executionsChart, this.latencyChart, this.qpsChart];
    return (
      allCharts.every(chart => chart !== undefined) &&
      allCharts.some(
        (chart: LineChart | undefined): boolean =>
          !!chart?.data?.some(lineChartData => lineChartData.series.some(p => p.value !== 0)),
      )
    );
  }
}
