import { ChangeDetectionStrategy, Component } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  BundleDataTableColumn,
  BundleDataTableData,
  BundleDataTableRow,
} from '../../global/components/bundle-data-table/bundle-data.types';
import { OnDestroyComponent } from '../../global/components/on-destroy/on-destroy.component';
import { BackendService } from '@squidcloud/console-web/app/backend/backend.service';
import { getServiceNameFromFunctionName } from '@squidcloud/console-common/types/metrics.types';

@Component({
  selector: 'app-schedulers',
  templateUrl: './schedulers.component.html',
  styleUrls: ['./schedulers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchedulersComponent extends OnDestroyComponent {
  bundleDataTableDataObs: Observable<BundleDataTableData | undefined>;

  constructor(private readonly backendService: BackendService) {
    super();

    this.bundleDataTableDataObs = this.backendService.getMetricsObs(this.onDestroy).pipe(
      map(metricsResponse => {
        const metrics = metricsResponse.functionsExecutionCounts?.['scheduler'];
        if (!metrics) return undefined;
        const rows: Array<BundleDataTableRow> = metrics.functions.map(metric => {
          const functionData = metric.functionData;
          const counts = metric.counts;
          const extras = functionData.extras;
          const schedulerId = extras['id'] as string;
          const functionName = functionData.serviceFunctionName as string;
          const cronExpression = extras['cronExpression'] as string;
          const columns: Array<BundleDataTableColumn> = [
            { value: schedulerId, tooltip: schedulerId },
            { value: `${counts.success}`, sortValue: counts.success },
            { value: `${counts.errors}`, sortValue: counts.errors, class: counts.errors > 0 ? 'error' : 'muted' },
            { value: cronExpression },
            { value: getServiceNameFromFunctionName(functionName) },
          ];
          return { columns };
        });

        this.backendService.reportBackendMetadataAvailable(!!rows.length);
        if (!rows.length) return undefined;

        return {
          headerRow: ['ID', 'Executions', 'Errors', 'Cron expression', 'Service'],
          rows,
          metrics: metrics.charts,
        };
      }),
    );

    this.backendService.reportBackendMetadataAvailable(false);
  }
}
