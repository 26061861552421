import { truthy } from 'assertic';
import { BehaviorSubject, filter, firstValueFrom } from 'rxjs';
import { DebugLogger } from '../../internal-common/src/utils/global.utils';
import { getApplicationUrl } from '../../internal-common/src/utils/http';
import { AuthManager } from './auth.manager';
import { ClientIdService } from './client-id.service';
import { DestructManager } from './destruct.manager';
import { SquidRegion } from './public-types';
import { RateLimiter } from './rate-limiter';
import { HttpResponse, rawSquidHttpPost } from './squid-http-client';
import { BlobAndFilename } from './types';

/** @internal */
export class RpcManager {
  private readonly staticHeaders: Record<string, string> = {};
  private readonly onGoingRpcCounter = new BehaviorSubject(0);

  private readonly rateLimiters: Record<string, RateLimiter>;

  constructor(
    private readonly region: SquidRegion,
    private readonly appId: string,
    destructManager: DestructManager,
    headers: Record<string, string>,
    private readonly authManager: AuthManager,
    private readonly clientIdService: ClientIdService,
  ) {
    for (const [key, value] of Object.entries(headers)) {
      this.setStaticHeader(key, value);
    }

    this.clientIdService.observeClientId().subscribe(clientId => {
      if (clientId) {
        this.setStaticHeader('x-squid-clientid', clientId);
      } else {
        this.deleteStaticHeader('x-squid-clientid');
      }
    });

    destructManager.onDestruct(async () => {
      await this.awaitAllSettled();
    });

    const apiKey = this.authManager.getApiKey();
    const rateLimiterMultiplier = apiKey ? 5 : 1;
    this.rateLimiters = {
      default: new RateLimiter(60 * rateLimiterMultiplier, 5),
      ai: new RateLimiter(20 * rateLimiterMultiplier, 5),
      secret: new RateLimiter(20 * rateLimiterMultiplier, 5),
    };
  }

  async getAuthHeaders(): Promise<Record<string, string>> {
    const apiKey = this.authManager.getApiKey();
    if (apiKey) {
      return { Authorization: `ApiKey ${apiKey}` };
    }

    const { token, integrationId } = await this.authManager.getAuthData();
    if (!token) return {};
    let header = `Bearer ${token}`;
    if (integrationId) {
      header += `; IntegrationId ${integrationId}`;
    }
    return { Authorization: header };
  }

  async awaitAllSettled(): Promise<void> {
    await firstValueFrom(this.onGoingRpcCounter.pipe(filter(value => value === 0)));
  }

  setStaticHeader(key: string, value: string): void {
    this.staticHeaders[key] = value;
  }

  deleteStaticHeader(key: string): void {
    delete this.staticHeaders[key];
  }

  getStaticHeaders(): Record<string, string> {
    return this.staticHeaders;
  }

  async post<ResponseType = unknown, RequestType = unknown>(
    path: string,
    message: RequestType,
    files: Array<File | BlobAndFilename> = [],
    filesFieldName = 'files',
  ): Promise<ResponseType> {
    const response = await this.rawPost(path, message, files, filesFieldName);
    return response.body as ResponseType;
  }

  async rawPost<ResponseType = unknown, RequestType = unknown>(
    path: string,
    message: RequestType,
    files: Array<File | BlobAndFilename> = [],
    filesFieldName = 'files',
    extractErrorMessage = true,
  ): Promise<HttpResponse<ResponseType>> {
    this.onGoingRpcCounter.next(this.onGoingRpcCounter.value + 1);
    try {
      await this.getRateLimiterBucket(path).consume();
      const authHeaders = await this.getAuthHeaders();
      const headers: Record<string, string> = { ...this.staticHeaders, ...authHeaders };
      DebugLogger.debug(`sending request: path: ${path} message: ${JSON.stringify(message)}`);
      const url = getApplicationUrl(this.region, this.appId, path);
      return (await rawSquidHttpPost({
        url,
        headers,
        message,
        files,
        filesFieldName,
        extractErrorMessage,
      })) as HttpResponse<ResponseType>;
    } finally {
      this.onGoingRpcCounter.next(this.onGoingRpcCounter.value - 1);
    }
  }

  private getRateLimiterBucket(path: string): RateLimiter {
    if (path.startsWith('ai/chatbot')) {
      return truthy(this.rateLimiters['ai'], 'MISSING_RATE_LIMITER_AI');
    }
    if (path.startsWith('secret/')) {
      return truthy(this.rateLimiters['secret'], 'MISSING_RATE_LIMITER_SECRETS');
    }
    return truthy(this.rateLimiters['default'], 'MISSING_RATE_LIMITER_DEFAULT');
  }
}
