const kotlinControllers = [
  'aiData',
  'api',
  'application',
  'application-kotlin',
  'backend-function',
  'extraction',
  'internal-storage',
  'internalCodeExecutor',
  'mutation',
  'named-query',
  'native-query',
  'observability',
  'openapi',
  'query',
  'queue',
  'quota',
  'secret',
  'storage',
  'webhooks',
  'ws',
  'personalStorage',
];

export function getApplicationUrl(regionPrefix: string, appId: string, path: string): string {
  const baseUrl = 'https://squid.cloud';
  const parsedBaseUrl = new URL(baseUrl);
  parsedBaseUrl.host = `${appId}.${regionPrefix}.${parsedBaseUrl.host}`;
  const controller = path.replace(/^\//g, '').split('/')[0] || '';

  if (isLocal(regionPrefix)) {
    parsedBaseUrl.protocol = 'http';
    parsedBaseUrl.port = kotlinControllers.includes(controller) ? '8001' : '8000';
    if (isAndroid(regionPrefix)) {
      parsedBaseUrl.host = '10.0.2.2';
    } else if (isIOS(regionPrefix)) {
      parsedBaseUrl.host = 'localhost';
    }
  }

  const url = parsedBaseUrl.toString();
  path = path.startsWith('/') ? path.slice(1) : path;
  const urlWithoutTrailingSlash = url.replace(/\/$/g, '');
  return path.length ? `${urlWithoutTrailingSlash}/${path}` : urlWithoutTrailingSlash;
}

export function getApplicationHttpHeaders(regionPrefix: string, appId: string): Record<string, string> {
  const headers: Record<string, string> = {};
  if (isLocal(regionPrefix)) {
    headers['x-squid-appid'] = appId;
  }

  return headers;
}

function isLocal(regionPrefix: string): boolean {
  return /^local/.test(regionPrefix);
}

function isAndroid(regionPrefix: string): boolean {
  return /android$/.test(regionPrefix);
}

function isIOS(regionPrefix: string): boolean {
  return /ios$/.test(regionPrefix);
}
