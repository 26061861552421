import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { AiTestChatFlyOutService, ChatMessage } from './ai-test-chat-fly-out.service';
import { GlobalUiService } from '@squidcloud/console-web/app/global/services/global-ui.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'ai-test-chat-fly-out',
  templateUrl: './ai-test-chat-fly-out.component.html',
  styleUrls: ['./ai-test-chat-fly-out.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiTestChatFlyOutComponent {
  testChatActiveObs = this.aiTestChatFlyOutService.observeTestChatActive();
  widgetParamsObs = this.aiTestChatFlyOutService.observeWidgetParams();
  historyEnabled = true;

  minimized = false;

  constructor(
    private readonly aiTestChatFlyOutService: AiTestChatFlyOutService,
    private readonly globalUiService: GlobalUiService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.aiTestChatFlyOutService
      .observeTestChatActive()
      .pipe(takeUntilDestroyed())
      .subscribe(showChat => {
        this.minimized = !showChat;
        cdr.markForCheck();
      });
  }

  closeTestChat(): void {
    this.globalUiService.showConfirmationDialog(
      'Close Chatbot?',
      'Closing chatbot will permanently lose this chat and its history. Minimize instead to preserve chat history.',
      'Close',
      () => {
        this.aiTestChatFlyOutService.closeTestChat();
      },
    );
  }

  toggleMinimize(): void {
    this.minimized = !this.minimized;
    this.cdr.markForCheck();
  }

  onChange(event: Event): void {
    const history = (event as Event & { detail: { history: Array<ChatMessage> } }).detail.history;
    this.aiTestChatFlyOutService.setHistory(history || []);
  }
}
