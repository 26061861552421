import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  BundleDataTableColumn,
  BundleDataTableData,
  BundleDataTableRow,
} from '../../global/components/bundle-data-table/bundle-data.types';
import { map, Observable } from 'rxjs';
import { OnDestroyComponent } from '../../global/components/on-destroy/on-destroy.component';
import { BackendService } from '@squidcloud/console-web/app/backend/backend.service';
import { getServiceNameFromFunctionName } from '@squidcloud/console-common/types/metrics.types';
import { environment } from '@squidcloud/console-web/environments/environment';

@Component({
  selector: 'app-executables',
  templateUrl: './executables.component.html',
  styleUrls: ['./executables.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExecutablesComponent extends OnDestroyComponent {
  bundleDataTableDataObs: Observable<BundleDataTableData | undefined>;

  constructor(private readonly backendService: BackendService) {
    super();
    this.backendService.reportBackendMetadataAvailable(false);

    this.bundleDataTableDataObs = this.backendService.getMetricsObs(this.onDestroy).pipe(
      map(metricsResponse => {
        const metrics = metricsResponse.functionsExecutionCounts?.['executable'];
        if (!metrics) return undefined;
        const rows: Array<BundleDataTableRow> = metrics.functions
          .sort((m1, m2) => {
            // Sort executables by name.
            const functionName1 = m1.functionData.extras['id'] as string;
            const functionName2 = m2.functionData.extras['id'] as string;
            return functionName1.localeCompare(functionName2);
          })
          .map(metric => {
            const functionData = metric.functionData;
            const counts = metric.counts;
            const extras = functionData.extras;
            const shortFunctionName = extras['id'] as string;
            const functionName = functionData.serviceFunctionName as string;
            const columns: Array<BundleDataTableColumn> = [
              { value: shortFunctionName },
              { value: `${counts.success}`, sortValue: counts.success },
              {
                value: `${counts.errors}`,
                sortValue: counts.errors,
                class: counts.errors > 0 ? 'error' : 'muted',
              },
              { value: getServiceNameFromFunctionName(functionName) },
            ];
            return { columns, link: '/someLink' };
          });

        this.backendService.reportBackendMetadataAvailable(!!rows.length);
        if (!rows.length && environment.stage !== 'local') {
          // Special behavior for local env to debug charts even if bundle metadata is empty.
          return undefined;
        }
        return {
          headerRow: ['Name', 'Executions', 'Errors', 'Service'],
          rows,
          metrics: metrics.charts,
        };
      }),
    );
  }
}
