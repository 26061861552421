import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { OrganizationService } from '@squidcloud/console-web/app/organization/organization.service';
import { FormConfig } from '@squidcloud/console-web/app/global/components/magic-form/magic-form.component';
import { OrganizationContactInfo } from '@squidcloud/console-common/types/organization.types';
import { SnackBarService } from '@squidcloud/console-web/app/global/services/snack-bar.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter } from 'rxjs';

@Component({
  selector: 'organization-contact-info',
  templateUrl: './organization-contact-info.component.html',
  styleUrl: './organization-contact-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationContactInfoComponent {
  formConfig: FormConfig = {
    formElements: [],
    disabled: true,
    submitButtonText: 'Update',
    layoutType: 'page',
    hideRequiredTextForValidValues: true,
    onSubmit: res => void this.updateOrganizationContactInfo(res),
  };

  constructor(
    protected readonly organizationService: OrganizationService,
    private readonly snackBar: SnackBarService,
    cdr: ChangeDetectorRef,
  ) {
    this.organizationService.currentOrganization$.pipe(filter(Boolean), takeUntilDestroyed()).subscribe(org => {
      this.formConfig = { ...this.formConfig, formElements: [] }; // Make a new object (reference) after every update.
      cdr.markForCheck();
      if (org === undefined) {
        return;
      }
      this.formConfig.disabled = organizationService.getMyRoleInCurrentOrg() !== 'ADMIN';
      const contactInfo = org.contactInfo || {
        companyName: '',
        firstName: '',
        lastName: '',
        jobTitle: '',
        email: '',
        phoneNumber: '',
      };
      this.formConfig.formElements = [
        {
          type: 'input',
          inputType: 'text',
          nameInForm: 'companyName',
          label: 'Company Name',
          required: true,
          floatLabel: 'always',
          defaultValue: contactInfo.companyName,
          description: 'The official name of the organization or business.',
        },
        {
          type: 'input',
          inputType: 'text',
          nameInForm: 'firstName',
          label: 'First Name',
          required: true,
          floatLabel: 'always',
          defaultValue: contactInfo.firstName,
          description: 'The first name of the primary contact person.',
        },
        {
          type: 'input',
          inputType: 'text',
          nameInForm: 'lastName',
          label: 'Last Name',
          required: true,
          floatLabel: 'always',
          defaultValue: contactInfo.lastName,
          description: 'The last name or surname of the primary contact person.',
        },
        {
          type: 'input',
          inputType: 'text',
          nameInForm: 'jobTitle',
          label: 'Job Title',
          required: true,
          floatLabel: 'always',
          defaultValue: contactInfo.jobTitle,
          description: 'The role or position held by the contact person.',
        },
        {
          type: 'input',
          inputType: 'email',
          nameInForm: 'email',
          label: 'Work Email',
          required: true,
          floatLabel: 'always',
          defaultValue: contactInfo.email,
          description: 'The email address used for official communications.',
        },
        {
          type: 'input',
          inputType: 'text',
          nameInForm: 'phoneNumber',
          label: 'Phone Number',
          required: true,
          floatLabel: 'always',
          defaultValue: contactInfo.phoneNumber,
          description: 'The primary contact phone number for reaching the organization or contact person.',
        },
      ];
    });
  }

  private async updateOrganizationContactInfo(formFields: Record<string, unknown>): Promise<void> {
    const organization = this.organizationService.getCurrentOrganizationOrFail();
    const contactInfo = formFields as unknown as OrganizationContactInfo;
    try {
      await this.organizationService.updateContactInfo(organization.id, contactInfo);
    } catch (error) {
      console.error('Failed to update contact info', error);
      this.snackBar.warning('Unable to update application name');
      return;
    }
    this.snackBar.success('Organization contact info updated');
  }
}
