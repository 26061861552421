<mat-icon [matMenuTriggerData]="{element}"
          [matMenuTriggerFor]="fieldDropdown"
          class="action_icon"
          svgIcon="menu_dots_icon" />

<mat-menu #fieldDropdown="matMenu"
          class="field_dropdown dropdown_menu"
          xPosition="before">
  <ng-template let-element="element" matMenuContent [typeGuard]="{element: string}">
    <button *ngIf="edit.observed"
            (click)="edit.emit(element)"
            class="menu_item" mat-menu-item>
      <mat-icon class="edit_icon menu_icon" svgIcon="edit_icon" />
      Edit Field
    </button>
    <button *ngIf="duplicate.observed && ('ADMIN' | minRole)"
            (click)="duplicate.emit(element)"
            class="menu_item" mat-menu-item>
      <mat-icon class="duplicate_icon menu_icon" svgIcon="duplicate_icon" />
      Duplicate
    </button>
    <button *ngIf="hide.observed && ('ADMIN' | minRole) && !hidden"
            (click)="hide.emit(element)"
            [disabled]="disableHide"
            [matTooltip]="disableHide ? 'Primary keys cannot be hidden.' : 'Hidden fields exist in the database but are not used by Squid or exposed to users.'"
            class="menu_item" mat-menu-item>
      <mat-icon class="reveal_icon menu_icon" svgIcon="reveal_icon" />
      Hide Field
    </button>
    <button *ngIf="hide.observed && ('ADMIN' | minRole) && hidden"
            (click)="hide.emit(element)"
            class="menu_item" mat-menu-item>
      <mat-icon class="eye_icon menu_icon" svgIcon="eye_icon" />
      Show Field
    </button>
    <ng-container *ngIf="delete.observed && ('ADMIN' | minRole)">
      <div class="menu_divider"></div>
      <button (click)="delete.emit(element)"
              class="menu_item red_menu_item"
              mat-menu-item>
        <mat-icon class="trash_icon menu_icon" svgIcon="trash_icon" />
        Delete
      </button>
    </ng-container>
  </ng-template>
</mat-menu>
