import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationService } from '../../application/application.service';
import { IntegrationService } from '../integration.service';
import { getPageParameter, TAB_ID_PARAMETER } from '@squidcloud/console-web/app/utils/http-utils';

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegrationsComponent {
  selectedIndex = 0;
  readonly applicationObs = this.applicationService.observeCurrentApplication();
  private readonly tabIdToIndexMap: Record<string, number> = {
    current: 0,
    available: 1,
  };
  private readonly indexToTabIdMap: Record<number, string> = Object.entries(this.tabIdToIndexMap).reduce(
    (acc, [key, value]) => {
      acc[value] = key;
      return acc;
    },
    {} as Record<number, string>,
  );

  constructor(
    activatedRoute: ActivatedRoute,
    private readonly integrationService: IntegrationService,
    private readonly applicationService: ApplicationService,
    private readonly router: Router,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    activatedRoute.params.subscribe(() => {
      const tabId = getPageParameter(TAB_ID_PARAMETER, activatedRoute.snapshot) || this.indexToTabIdMap[0];
      this.selectedIndex = this.tabIdToIndexMap[tabId];
      this.changeDetectorRef.markForCheck();
    });
  }

  async changeUrl(index: number | null): Promise<void> {
    if (index === null) index = 0;
    await this.router.navigate([
      '/application',
      this.applicationService.getCurrentApplicationOrFail().appId,
      'integrations',
      this.indexToTabIdMap[index],
    ]);
  }

  showRequestIntegrationDialog(): void {
    this.integrationService.showRequestIntegrationDialog();
  }
}
