import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OrganizationService } from '@squidcloud/console-web/app/organization/organization.service';
import { OrganizationComponentTabName } from '@squidcloud/console-web/app/organization/organization.component';

@Component({
  templateUrl: './missed-organization-details-dialog.component.html',
  styleUrl: './missed-organization-details-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MissedOrganizationDetailsDialogComponent {
  private readonly organizationId: string;

  constructor(
    private readonly router: Router,
    organizationService: OrganizationService,
  ) {
    this.organizationId = organizationService.getCurrentOrganizationOrFail().id;
  }

  static show(dialog: MatDialog): MatDialogRef<void> {
    return dialog.open<void>(MissedOrganizationDetailsDialogComponent, {
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'modal',
    });
  }

  navigateToOrganizationContactsPage(): void {
    const tab: OrganizationComponentTabName = 'contacts';
    void this.router.navigate(['/organization', this.organizationId, tab]);
  }
}
