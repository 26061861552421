<mat-dialog-content class="missed_org_details_dialog">
  <dialog-close-button></dialog-close-button>

  <div class="logo_title_and_message">
    <div class="logo">
      <!--suppress AngularNgOptimizedImage. Reason: image is resized by CSS. -->
      <img src="assets/images/rudder_stressed.png" alt="Logo" />
    </div>
    <div class="title_and_message">
      <div class="title">Contact Details Required</div>
      <div class="message">
        To proceed with creating an application, we need to have the contact details of your organization.
        <br>
        Please fill in the necessary details before continuing.
      </div>
    </div>
  </div>
  <div class="buttons_row">
    <a mat-flat-button [mat-dialog-close]="true" (click)="navigateToOrganizationContactsPage()"
       target="_blank" class="primary_button large_button">Fill Contact Details</a>
  </div>

</mat-dialog-content>

